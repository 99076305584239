@import "../../assets/styles/Settings/screens.scss";
@import "../../assets/styles/Settings/colors.scss";
body {
    overflow-x: hidden;
}
.section {
  &-different {
    margin-top: 80px;
    height: 1000px;
    .container {
    }
    @include tablet {
        height: 800px;
    }
    @include phone {
        height: 600px;
    }

    .text {
      &--h2 {
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
      &--p {
        opacity: 0.4;
      }
      &--decorative-1 {
        background: linear-gradient(
          269.87deg,
          #ffffff 5.57%,
          rgba(255, 255, 255, 0) 118.86%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .goldcard-bg {
      position: absolute;
      z-index: -1;
      max-width: 1930px;
      width: 100%;
      top: 300px;
      left: -276px;
      
      @include tablet {
        max-width: 1538px;
        min-width: 1339px;
        top: 500px;
        left: -300px;
      }
      @include phone {
        min-width: 892px;
        top: 300px;
        left: -280px;
      }
    }
  }
  &-gold-card {
    .container {
        margin: 0 auto;
    }
    .text {
        &--lg {
            font-size: 220px;
            @include phone {
                font-size: 135px;
            }
        }
    }
  }
  &-shadow {
    margin: 800px auto;
    
    
    
    @include tablet {
        margin: 0 auto;
        padding-bottom: 300px;
    }
    @include phone {
        margin: 40px auto;
        padding-bottom: 140px;
        
    }
    .text{
        &--h4{
            @include tablet {
                font-size: 32px;
                line-height: 38px;
            }
            @include phone {
                font-size: 24px;
                line-height: 29px;
            }
        }
    }
    figure {
        position: absolute; 
        left: 0; 
        right: 0; 
        top: -150px;
        margin-left: auto; 
        margin-right: auto; 
        max-width: 510px;
        img {
            max-width: 510px;
            
        }
        @include phone {
        max-width: 355px;
        top: -140px;
        img {
            max-width: 355px;
        }
        
        }
    }
  }
  &-flights {
    
  }
  &-questions {
    
  }
  &-contact {
    
  }
  &-themes {
    padding-bottom: 300px;
    background-color: $shade-light;
    
    .text {
        &-center{
            margin-top: 160px;
        }
        &--h3 {
            margin-bottom: 40px;
            @include tablet {
                font-size: 40px;
                line-height: 48px;
            }
        }
        &--subtitle {
            @include tablet {
                font-size: 16px;
                line-height: 24px;
            }
            @include phone {
                font-size: 18px;
                line-height: 32px;
            }
        }
    }
    .product-box {
        
        @include desktop {
            height: 612px;
            padding: 50px 50px;
        }
        @include tablet {
            height: 302px;
            padding: 30px 30px;
        }
        @include phone {
            height: 342px;
            padding: 30px 30px;
        }

        figure{
            position: absolute;
            min-width: 1190px;
            width: 100%;
            top: 0;
            vertical-align: middle;
            max-height: 612px;
            left: 50%;
            transform: translateX(-50%);
            img {
                width: 100%;
            }

            @include tablet {
                top: -100px;
                min-width:966px;

            }
            @include phone {
                top: 0;
                min-width: 691px;

            }


        }
        button {
            position: absolute;
            bottom: 50px;
            left: 50px;
            @include tablet {
                padding: 5px;
                left: 30px;
                span {
                    font-size: 12px;
                }
            }
            @include phone {
                padding: 5px;
                left: 30px;
                span {
                    font-size: 12px;
                }
            }
        }
        .text {

            &--h3 {
                margin-bottom: 10px;
            }

            &--caption {
                opacity: 0.7;
                @include tablet {
                    font-size: 12px;
                    line-height: 16px;

                }
                @include phone {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        &--modern-art {
            

            figure {

                @include phone {
                    top: 0;
                    min-width:1100px;
                    margin: 0 auto;
                }

            }
            .text {

                &--caption {
                    opacity: 0.7;
                }
            }

        }


    }
  }
}
.footer {
    
}
