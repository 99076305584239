.product-box {
  $product-box: &;
  position: relative;
  background: $white;
  border-radius: 32px;
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  //height: 526px;
  height: 506px;
  overflow: hidden;

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    padding: 24px;
  }

  img {
    @include phone {
      width: 100%;
    }
  }

  &__type {
    line-height: 44px;
    color: $shade-dark;
  }

  &__title {
    width: 100%;
    //max-width: 236px;
    margin-bottom: 20px;
    color: $shade-dark;

    @media screen and (min-width: 1248.5px) {
      line-height: 64px;
    }
    @media screen and (max-width: 1248px) {
      line-height: 1;
    }
  }

  &__icons {
    @include flex-center-start;
    i {
      @include flex-center;
      width: 32px;
      height: 32px;
      border: 1px solid rgba(30, 33, 41, 0.2);
      border-radius: 8px;
      padding: 4px;
      font-size: 24px;
      color: $shade-dark;

      + i {
        margin-left: 10px;
      }
    }
  }

  &--pricing {
    @extend .product-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    gap: 24px;

    @media screen and (min-width: 1248.5px) {
      padding: 102px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      flex-direction: column;
      padding: 52px;
    }

    @include phone {
      flex-direction: column;
      padding: 32px 24px;
    }

    .product-box__left {
      grid-area: left;

      .text--h3 {
        margin-bottom: 16px;
      }
    }
    .product-box__right {
      grid-area: content;

      > div {
        .text--h6 {
          margin-bottom: 10px;
          line-height: 45px;
        }
        .text--caption {
          line-height: 32px;
          color: $shade-dark-07;
        }

        + div {
          margin-top: 50px;
        }
      }
    }
    .product-box__call {
      grid-area: call;
      align-self: flex-end;

      @include phone {
        margin-top: 64px;
        text-align: center;
      }
    }
  }

  &--dark, &--pink {
    #{$product-box}__title, #{$product-box}__type, i {
      color: $white;
    }
    i {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &--dark {
    background: radial-gradient(184.72% 73.41% at 50% 62.36%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%), radial-gradient(127.92% 121.25% at 50% 123.75%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%), radial-gradient(94.36% 101.63% at 100% 0%, rgba(180, 232, 255, 0.3) 14.06%, rgba(172, 230, 255, 0.3) 52.3%, rgba(255, 255, 255, 0) 100%), radial-gradient(103.16% 103.16% at 4.11% -3.16%, rgba(203, 186, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%), radial-gradient(101.6% 109.19% at 31.22% 14.98%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #0C0D10;
  }

  &--pink {
    background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(359.9deg, #CAF6D9 -7.61%, rgba(202, 246, 217, 0) 111.71%), linear-gradient(354.35deg, #AD00FF -12.34%, rgba(220, 211, 248, 0) 100.15%), #C172E6;
  }
  &--pink-v2 {
    background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(359.9deg, #CAF6D9 -7.61%, rgba(202, 246, 217, 0) 111.71%), #FF9E98;
  }

  &--custom {
    background: $shade-dark;

    #{$product-box}__type, #{$product-box}__title, .button span, .button i {
      color: $white;
    }
  }

  &--horizontal {
    #{$product-box}__icons {
      position: absolute;
      bottom: 62px;
      left: 50px;
    }
  }
  &--vertical {
    height: 100%;
    #{$product-box}__icons {
      position: absolute;
      bottom: 62px;
      left: 50px;
      
    }
  }

  &--v2 {
    @include flex-start-between-column;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  img, figure {
    z-index: 0;
  }
}