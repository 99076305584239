.section-financial {
  position: relative;
  background: $shade-light;
  padding: 240px 0 170px;

  .section__title {
    @media screen and (min-width: 1248.5px) {
      max-width: 640px;
      margin-bottom: 60px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {

    }

    @include phone {

    }
  }

  .text {
    &--h3 {
      @include tablet {
        font-size: 40px;
        line-height: 48px;
      }
      @include phone {
        font-size: 40px;
        line-height: 48px;
      }
    }  
    &--h5 {
      @include tablet {
        font-size: 16px;
        line-height: 24px;
      }
      @include phone {
        font-size: 18px;
        line-height: 32px;
      }
    }  
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1200px;
    background: radial-gradient(89.94% 75.19% at 100% 106.82%, #CAF6D9 0%, rgba(202, 246, 217, 0) 100%), radial-gradient(80.8% 67.55% at 0% 100%, #FF9E98 0%, rgba(255, 207, 204, 0) 100%), radial-gradient(165.81% 84.32% at 100% 100%, #AD00FF 0%, rgba(220, 211, 248, 0) 100%), #F3F4F6;
  }

  .container {
    > .text--h3 {
      max-width: 628px;
      margin-bottom: 120px;

      @include phone {
        margin-bottom: 30px;
      }
    }

    > .text--h4 {
      margin-bottom: 160px;
    }

    .product-box {
      @media screen and (min-width: 1248.5px) {
        height: 645px;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        height: 302px;
      }

      @include phone {
        height: 582px;
      }

      &--pricing {
        height: auto;

        .button {
          @include phone {
            justify-content: flex-start;
            margin-bottom: 40px;
          }
        }
      }

      &--fast, &--secure {


        .button {
          @media screen and (min-width: 768px) and (max-width: 1248px) {
            justify-content: flex-start;
          }
        }
      }

      &--fast {
        figure {
          position: absolute;
          width: 100%;
          left: 50%;
          transform: translateX(-50%);

          @media screen and (min-width: 1248.5px) {
            bottom: 48px;
          }

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            bottom: -50px;
          }

          @include phone {
            bottom: 0;
          }
        }
      }
      &--secure {

        figure {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          @media screen and (min-width: 1248.5px) {
            bottom: -120px;
          }

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            bottom: -50px;
            left: 200px;
            img {
              width: 270px;
            }
          }

          @include phone {
            bottom: 0;
            img {
              position: absolute;
              bottom: -50px;
            }
          }
        }
      }
    }
  }
}