.price-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  border-radius: 12px;
  background: $purple;
  box-sizing: border-box;

  @media screen and (min-width: 1248.5px) {
    width: 190px;
    height: 230px;
    padding: 25px;
  }

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    padding: 12px;
    width: 134px;
    height: 124px;
  }

  @include phone {
    padding: 12px;
    width: 100px;
    height: 120px;
  }

  &__text {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: $shade-dark;

    @media screen and (min-width: 1248.5px) {
      font-size: 22px;
      line-height: 38px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    @include phone {
      font-size: 11px;
      line-height: 20px;
    }
  }

  &__price {
    font-weight: 500;
    letter-spacing: 0.01em;
    color: $shade-dark;

    @media screen and (min-width: 1248.5px) {
      font-size: 28px;
      line-height: 38px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      font-size: 24px;
      line-height: 28px;
    }

    @include phone {
      font-size: 15px;
      line-height: 20px;
    }
  }


  &__icon {
    position: absolute;
    color: $shade-dark;

    @media screen and (min-width: 1248.5px) {
      top: 25px;
      left: 25px;
      font-size: 50px;
    }

    @media screen and (max-width: 1248px) {
      top: 12px;
      left: 12px;
      font-size: 24px;
    }
  }

  span {
    display: block;
    color: $shade-dark;
  }

  &--green {
    background: $green;
  }
  &--pink {
    background: $pink;
  }
  &--aqua {
    background: $aqua;
  }
}