@import '../../assets/styles/Settings/colors.scss';

.ant-modal {
  $ant-modal: &;

  &-header {
    padding: 20px 24px;
  }
  &-body {
    max-height: 80vh;
    overflow: scroll;

    > * {
      display: block;

      + * {
        margin-top: 20px;
      }
    }
  }
  &-close {
    position: absolute;
    top: 22px;
    right: 15px;

    &-x {
      width: 24px;
      height: 24px;

      i {
        display: block;
        font-size: 18px;

        &::before {
          color: $black;
        }
      }
    }
  }

  &--qr {
    
    #{ $ant-modal }-body {
      border-radius: 20px !important;
      text-align: center;
    }
    .ant-modal-content {
      border-radius: 25px;
    }
  }
  &--recaptcha {
    #{ $ant-modal }-body {
      text-align: center;
      
    }
    * :not(:last-child) {
      margin: 20px 0;
    }
    .error {
      color: red;
    }
    .recaptcha {
      
        div{
            width: 100% !important;
        }



    }
  }

}



