.checkbox {
  &__text {
    color: $abbey;

    a {
      color: $purple-base;
    }
  }

  .ant-checkbox {
    top: 0;
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      white-space: normal;

      &:hover {
        .ant-checkbox-inner {
          background: $white !important;
          border-color: $shade-dark !important;
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: $shade-dark !important;
            border-color: $shade-dark !important;
          }
        }
      }
    }

    &-input {
      width: 24px;
      height: 24px;
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $shade-dark;
        background-color: $shade-dark;
      }
    }

    .ant-checkbox-inner {
      background: $shade-light;
      border: 2px solid $shade-dark;
      border-radius: 3px;
      width: 24px;
      height: 24px;
    }

    // checkbox check
    &-checked {
      .ant-checkbox-inner {
        background: $shade-dark;

        &:after {
          width: 6px;
          height: 10px;
          top: 50%;
          left: 25%;
          border-color: $white;
        }
      }

      &:after {
        display: none;
      }
    }
  }


}