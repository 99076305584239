.collapse {
  $collapse: &;

  .ant-collapse {
    background-color: unset;
    border: none;
    line-height: normal;
    border-radius: 0;

    &-item {
      border-bottom: none !important;

      + .ant-collapse-item {
        margin-top: 60px;
      }
    }

    &-header {
      padding: 0 !important;
      padding-inline-start: 0 !important;
      margin-bottom: 16px;

      &-text {
        @include flex-space-between;

        #{ $collapse }__title {
          color: $white;
        }

        button {
          @include flex-center;
          width: 32px;
          height: 32px;
          background: $white;
          border: none;
          border-radius: 50%;
          cursor: pointer;

          i {
            font-size: 24px;
          }
        }
      }
    }

    &-content {
      background-color: unset !important;
      border: none !important;


      .text--caption {
        color: $white-07;
      }

      > .ant-collapse-content-box {
        padding: 0 70px 0 0;
      }
    }
  }

  &--dark {
    .ant-collapse {
      &-header {
        &-text {
          #{ $collapse }__title {
            color: $shade-dark;
          }
        }
      }

      &-content {
        .text--caption {
          color: $shade-mid;
        }
      }
    }
  }
}
