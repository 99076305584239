.action-menu {
  $action-menu: &;
  @include flex-center-start;
  position: relative;
  background: $white;
  border: 1px solid $gray;
  width: 100%;

  @media screen and (min-width: 1248.5px) {
    box-shadow: 0 22.3837px 41.0367px rgba(0, 0, 0, 0.25);
    padding: 10px 12px;
    border-radius: 6px;
  }

  @media screen and (max-width: 1248px) {
    padding: 4px 6px;
    border-radius: 3px;
    box-shadow: 0px 11.1918px 20.5184px rgba(0, 0, 0, 0.25);
  }

  &.action-menu--passive {
    opacity: 0.3;
    box-shadow: none;
  }

  &__logo {
   @include flex-center;
    border-radius: 50%;
    color: $black;

    @media screen and (min-width: 1248.5px) {
      width: 32px;
      height: 32px;
      font-size: 19px;
      margin-right: 10px;
    }

    @media screen and (max-width: 1248px) {
      width: 16px;
      height: 16px;
      font-size: 10px;
      margin-right: 4px;
    }
  }

  span {
    letter-spacing: 0.02em;
    font-feature-settings: 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on, 'ss01' on;
    color: $shade-dark;
    font-weight: 500;

    @media screen and (min-width: 1248.5px) {
      font-size: 12px;
      line-height: 19px;
    }

    @media screen and (max-width: 1248px) {
      font-size: 6px;
      line-height: 10px;
    }

  }

  &__action {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);

    @media screen and (min-width: 1248.5px) {
      font-size: 25px;
    }

    @media screen and (max-width: 1248px) {
      font-size: 12px;
    }
  }

  &--green {
    #{ $action-menu }__logo {
      background: $green;
    }
  }
  &--pink {
    #{ $action-menu }__logo {
      background: $pink;
    }
  }
  &--aqua {
    #{ $action-menu }__logo {
      background: $aqua;
    }
  }
}