@import '../../assets/styles/Settings/screens.scss';

.section-contact-page {

  .text {

    &--h3 {
      @include phone {
        font-size: 64px;
        line-height: 77px;
      }
    }

    .title {
      @include tablet {
        font-size: 18px;
        line-height: 32px;
      }
      @include phone {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .info {
      @include tablet {
        font-size: 16px;
        line-height: 24px;
      }
      @include phone {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }

  .contact-texts {
    margin-bottom: 30px;



    hr {
      opacity: 20%;
    }

    * {
      margin-bottom: 30px;
    }
  }


  @include phone {
    text-align: center;
  }


}

.section-directors {

  .text {

    &--caption {
      opacity: 60%;
    }
    &--h3 {
      @include phone {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }



  .director {
    margin-bottom: 70px;

    * {
      margin-bottom: 15px;
    }
  }

  @include phone {
    text-align: center;
  }
}
