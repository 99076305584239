.range-slider {
  position: relative;
  padding-bottom: 12px;

  .ant-slider {
    margin: 0;
    padding: 0;

    @media screen and (min-width: 1248.5px) {
      height: 26px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      height: 12px;
    }

    @include phone {
      height: 26px;
    }

    &-track, &-rail {
      border-radius: 30px;

      @media screen and (min-width: 1248.5px) {
        height: 26px;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        height: 12px;
      }

      @include phone {
        height: 26px;
      }
    }

    &-track {
      background: linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), #C172E6;
      background: linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), linear-gradient(0deg, #C172E6, #C172E6);
    }
    &-rail {
      background: #EEEAF6;
    }

    &-handle {
      border: none;
      margin-top: -12px;
      background: linear-gradient(109.65deg, #FAE89E 2.17%, rgba(250, 232, 158, 0) 27.54%), linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), #C172E6;
      box-shadow: 0 3px 0 #C471F0, 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px;

      @media screen and (min-width: 1248.5px) {
        width: 95px;
        height: 47px;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        width: 64px;
        height: 32px;
      }

      @include phone {
        width: 95px;
        height: 47px;
      }
    }
  }

  &__text {
    display: block;
    text-align: right;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    font-feature-settings: 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on, 'ss01' on;
    color: $shade-dark;
  }
}