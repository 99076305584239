@import "../../assets/styles/Settings/colors.scss";
@import "../../assets/styles/Settings/mixins.scss";
@import "../../assets/styles/Settings/screens.scss";

.section-cards {
  background-image: url("../../../public/images/cards-bg.png");
  background-color: $shade-light;
  background-size: cover;
  background-position: center -440px;
  padding: 140px 0;

  @include phone {
    background-position: center -700px;
  }

  .container {
    > figure {
      max-width: 660px;
      margin: 118px auto 92px;

      img {
        width: 100%;
      }
    }
    .text {

      @include phone {
        &--h2 {
          font-size: 40px;
          line-height: 48px;
        }
        &--h4 {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }

    .button-group {
      @include phone {

        .button {
          min-width:70px ;
          height: 40px;
          + .button {
            margin-left: 6px;
          }
        }
        span {
          font-size: 14px;
        }
      }
      + .slider-box {
        margin-top: 100px;
      }
    }

    .slider-box {
      @media screen and (min-width: 1248.5px) {
        border-radius: 32px;
        padding: 80px;
        background: $white;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        border-radius: 24px;
        padding: 32px;
        background: $white;
      }

      @include phone {
      }

      &__title {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: $shade-dark;
        margin-bottom: 14px;
      }

      &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $shade-mid-07;
      }

      .range-slider {
        @media screen and (min-width: 1248.5px) {
          margin-bottom: 52px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          margin: 24px;
        }

        @include phone {
          margin: 32px;
        }
      }

      .input-wrapper {
        margin-bottom: 20px;

        @media screen and (min-width: 768px) {
          @include flex-space-between;
        }

        @include phone {
          display: block;
        }

        span {
          display: block;
        }
        .slider-box {
          &__input {
            @include flex-space-between;
            background: $shade-light;
            border-radius: 20px;
            padding: 16px;
            width: 100%;

            @media screen and (min-width: 768px) and (max-width: 1248px) {
              flex-direction: column;
              align-items: flex-start;
            }

            > div {
              @media screen and (min-width: 768px) and (max-width: 1248px) {
                order: 2;
              }
            }

            + .slider-box__input {
              @media screen and (min-width: 1248.5px) {
                margin-left: 65px;
              }

              @media screen and (min-width: 768px) and (max-width: 1248px) {
                margin-left: 24px;
              }

              @include phone {
                margin-top: 12px;
              }
            }

            figure {
              @media screen and (min-width: 768px) and (max-width: 1248px) {
                margin-bottom: 24px;
              }

              @media screen and (min-width: 768px) {
                max-width: 68px;
              }

              @include phone {
                max-width: 48px;
              }

              img {
                width: 100%;
              }
            }

            &__label {
              color: $shade-dark-23;
              font-size: 16px;
              line-height: 1;
              font-weight: 500;
              margin-bottom: 8px;
            }

            &__text {
              font-weight: 500;
              color: $shade-dark;
              line-height: 1;

              @media screen and (min-width: 768px) {
                font-size: 28px;
              }

              @include phone {
                font-size: 18px;
              }
            }
          }
        }
      }

      .text--caption {
        color: $shade-dark-04;
        line-height: 32px;
      }
    }
  }
}

.section-compare {
  position: relative;
  padding: 124px 0;

  &__title {
    max-width: 380px;

    @media screen and (max-width: 1248px) {
      margin-bottom: 80px;
    }
  }

  .accessory-1 {
    @media screen and (max-width: 1248px) {
      display: none;
    }

    span {
      @include desktop {
        white-space: nowrap;
        margin-left: -52%;
      }
    }
  }

  .table--custom--white {
    @include desktop {
      padding-top: 160px;
    }

    @media screen and (max-width: 1248px) {
      overflow-y: hidden;
    }

    .button-group {
      @media screen and (max-width: 1248px) {
        margin-bottom: 60px;
      }
    }

    .body {
      @media screen and (max-width: 1248px) {
        max-width: 424px;
        margin: 0 auto;
      }

      .cards-images {
        &__standart {
          @media screen and (max-width: 1248px) {
            margin-top: 160px;
          }
          &:before {
            @media screen and (max-width: 1248px) {
              left: 50%;
              transform: translateX(-50%);
              background-size: cover;
            }
          }

          figure {
            display: block;

            img {
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.papel-card {
  &-white {
    z-index: 8;


  }
  &-gold {
    z-index: 7;

  }
  &-black {
    z-index: 6;

  }
  &-colored {
    z-index: 5;

  }
  &-shadow {
    z-index: 4;

  }
  &-animation {
    margin: 120px auto 90px;
    text-align: center;

    figure {
      position: relative;
      @include phone {
        width: 100%;
        img {
          width: 100%;
        }
      }
      

      & + figure {
        margin-top: -300px;
        @include phone {
          margin-top: -45%;
        }
      }
    }
  }
}
