@import "../../assets/styles/Settings/colors.scss";
@import "../../assets/styles/Settings/screens.scss";

.accordion {
    position: relative;
    margin: 200px 0;

    .image {
        width: 100%;
        max-width: 100%;
        height: 100%;

        &--customer {
            min-width: 640px;
            height: 720px;

            @include phone {
                height: 300px;
                transform: scale(0.5);
                margin-left: -130px;
                margin-top: 60px;
            }
        }

        &--teams {
            width: 600px;
            height: 900px;
            display: block;

            @include phone {
                height: 420px;
                width: 100%;
            }
        }

        &--send-receive {
            overflow: hidden;
            max-width: 100%;
        }
    }

    &__content {
        width: 40%;
        margin: 0 0 0 60% !important;

        @include phone {
            width: 100%;
            min-height: auto;
            margin: 0 !important;
        }
    }

    &__img-area {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;

        @include tablet {
            transform: scale(0.6);
            top: 0;
            left: -140px;
        }

        @include phone {
            position: inherit;
            transform: none;
        }

        .accessory-card {
            width: 612px;

            @include phone {
                width: fit-content;
            }
        }

        .pos {
            margin-top: -75px;
            position: absolute;
            left: -140px;
            top: 285px;
            width: 636px;
            z-index: -1;

            @include phone {
                position: initial;
                width: 100%;
            }

            img {
                max-width: 100%;
                min-width: 100%;
            }
        }

        .papel-pos-card {
            width: 508px;

            @include phone {
                width: 100%;
            }

            img {
                max-width: 100%;
                min-width: 100%;
            }
        }

        .pos-card-color {
            position: absolute;
            z-index: -2;
            bottom: 217px;
            right: -40px;
            width: 382px;

            @include tablet {
                bottom: 110px;
            }

            @include phone {
                position: initial;
                margin-top: -150px;
                width: 300px;
                margin-left: 54px;
            }

            img {
                max-width: 100%;
                min-width: 100%;
            }
        }

        .teams {
            &-1 {
                width: 614px;

                @include phone {
                    width: 300px;
                    margin-top: 60px;
                }

                img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            &-2 {
                width: 611px;
                margin-top: -60px;

                @include phone {
                    width: 300px;
                }

                img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            &-3 {
                width: 614px;
                margin-top: -60px;

                @include phone {
                    width: 300px;
                }

                img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }
        }

        .customer {
            &-1,
            &-2,
            &-3,
            &-4 {
                position: absolute;

                img {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            &-1 {
                width: 565px;
                bottom: 0;
                left: -75px;
            }

            &-2 {
                width: 168px;
                right: 150px;
                top: 100px;

                @include phone {
                    top: -200px;
                }
            }

            &-3 {
                width: 396px;
                right: 0;
                bottom: 100px;
            }

            &-4 {
                width: 337px;
                top: 150px;
                left: -55px;

                @include phone {
                    top: -50px;
                }
            }
        }
    }

    .ant-tabs-content {
        position: inherit;
    }

    .ant-collapse {
        background: transparent;
        border: 0;

        &-header {
            @include phone {
                padding: 0;
            }

            &-text {
                opacity: 0.2;
                font-weight: 500;
                color: $shade-dark;
                font-size: 56px;
                line-height: 67px;
                font-feature-settings: "ss03" on, "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on, "cv06" on,
                    "cv07" on, "cv09" on, "cv10" on, "cv11" on, "ss01" on;

                @include tablet {
                    font-size: 32px;
                }

                @include phone {
                    font-size: 40px;
                    color: $white;
                }
            }
        }

        &-content {
            background: transparent;
            border: 0;

            &-box {
                padding: 0 16px !important;

                @include phone {
                    padding: 0 !important;
                }
            }
        }

        &-item {
            border: 0 !important;
            padding-bottom: 120px !important;

            @include tablet {
                padding-bottom: 90px !important;
            }

            .text--p {
                color: $shade-dark;

                @include phone {
                    font-size: 16px;
                    color: $white;
                }
            }

            &-active {
                .ant-collapse-header {
                    &-text {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
