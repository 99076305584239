@import '../../assets/styles/Settings/mixins.scss';
@import '../../assets/styles/Settings/colors.scss';
@import '../../assets/styles/Settings/screens.scss';

.section {
  &-help, &-help-question {
    background: $shade-light;
  }

  &-help {
    padding: 82px 0 120px;

    .text {
      &--h3 {
        display: block;
        text-align: center;

        @include phone {
          font-size: 40px;
          line-height: 48px;
        } 
      }
      &--h4 {

      }
      &--h6 {
        @include phone {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    .container {
      .ant-row--swiper {

        .swiper-container {
          @media screen and (max-width: 1248px) {
            width: calc(100% + 120px);
            margin-left: -60px;
            padding-left: 60px;
          }
        }
      }

      .product-box {
        height: 320px;
        @include flex-start-between-column;

        &__title {
          font-size: 40px;
          line-height: 48px;
          max-width: 306px;
          @include phone {
            font-size: 32px;
            line-height: 38px;
          } 
        }

        > figure {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
        }

        &--github {
          button {
            background: none;
            border: none;
            padding: 5px;
          }
        }
      }
    }

    .swiper-covered {
      @media screen and (min-width: 1248px) {
        pointer-events: none;
        max-width: 1248px;
        margin: 0 auto;
        box-sizing: border-box;
      }
    }
  }

  &-help-question {
  }
}
