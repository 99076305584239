@import 'src/assets/styles/Settings/colors.scss';

.select {
  position: relative;

  &--v1 {
    
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-multiple .ant-select-selector {
      background: $white-05 !important;
      border: 1px solid #e2e2e2 !important;
      border-radius: 15px;
      height: auto;
      width: 100%;
      margin-bottom: 20px;
  }
  .ant-select {
    width: 100%;
  }

  .ant-select-selection-placeholder, .ant-select-selection-item {

    color: $black !important;
    padding: 15px 20px !important;
  }
}

  &__prefix-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: $white-05;
    font-size: 24px;
  }
  &__afterfix-icon {
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
    color: $black;
    font-size: 24px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    min-width: 58px;
    padding-left: 24px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-multiple .ant-select-selector {
    background: none;
    border: none;
    height: auto;

    .ant-select-selection-placeholder, .ant-select-selection-item {
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      opacity: 0.7;

    }

    .ant-select-selection-search {
      display: none;
    }
  }
  .auto-h {
    .ant-select-selector {
      height: auto;
    }
  }

  .ant-select-arrow {
    display: none;
    transition: all .3s ease;

    i {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
}

.ant-select-item-option {
  @include flex-center-start;
  position: relative;
  height: 30px;
  line-height: 52px;
  padding: 0 10px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    width: calc(100% - 10px);
    height: 1px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  &-state {
    svg {
      width: 16px;
      height: auto;

      path {
        fill: #2a3775;
      }
    }
  }

  &-content {
    font-size: 15px;
    font-weight: 400;
    color: #293840;
  }
}

.ant-select-dropdown {
  padding: 0;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #fff;

  .ant-select-item-option-content {
    font-weight: 700;
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #fff;
}

.select-message-params {
  height: 35px;
  padding: 8px 15px;
  border-radius: 5px;
  background: #f2fbff;
  border: 1px solid #3fb0e8;
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.46;
    color: #3fb0e8;
    margin-left: 10px;
  }

  svg {
    height: auto;

    path {
      fill: #3fb0e8;
    }

    &:first-child {
      width: 10px;
    }

    &:last-child {
      width: 8px;
    }
  }

  &__dropdown {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 30px -5px rgba(0, 0, 0, 0.25);

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        list-style: none;
        padding: 10px 5px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.4;
        color: #293840;
      }
    }
  }
}
