@import "src/assets/styles/Settings/screens";
@import "src/assets/styles/Settings/colors";


.about-page {
  section {
    @media screen and (min-width: 1248.5px) {
      margin-bottom: 200px;
    }
    @media screen and (max-width: 1248px) {
      margin-bottom: 128px;
    }
  }

  .section-about {
    position: relative;
    padding-top: 90px;
    @media screen and (min-width: 1248.5px) {
      margin-bottom: 234px;
      padding-bottom: 778px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      margin-bottom: 128px;
      padding-bottom: 612px;
    }

    @include phone {
      margin-bottom: 64px;
      padding-bottom: 526px;
    }

    .text {

      &--h3 {
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
      &--h4 {
        @include phone {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-image: url("../../../public/images/office-photo.png");
      background-position: center;

      @media screen and (min-width: 1248.5px) {
        height: 650px;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        height: 544px;
      }

      @include phone {
        height: 462px;
      }
    }
  }

  .section-numbers {
    .ant-row {
      + .ant-row {
        padding-top: 60px;
        margin-top: 110px;
        border-top: 1px solid $white-04;
      }
    }
  }

  .section-team {
    .text {

      &--h3 {
        @include tablet {
          font-size: 40px;
          line-height: 48px;
        }
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
    .container {
      position: relative;
    }
    .ant-row--swiper {
      margin-top: 146px;
    }
  }

  .section-know-us {


    .text {

      &--h3 {
        @include tablet {
          font-size: 40px;
          line-height: 48px;
        }
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
      &--h4 {
        @include phone {
          font-size: 24px;
          line-height: 29px;
        }
      }
      &--h6 {
        @include phone {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    h3 {
      @media screen and (min-width: 1248.5px) {
        margin-bottom: 64px;

      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        margin-bottom: 20px;
      }


    }

    .button {
      @media screen and (max-width: 1248px) {
        margin-bottom: 68px;
      }
    }

    .person {
      @media screen and (min-width: 1248.5px) {
        margin: 64px 0;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        margin: 32px 0;
      }

      @include phone {
        margin: 24px 0;
      }
    }
  }
}