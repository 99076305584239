.phone {
  position: relative;
  box-sizing: border-box;
  background-size: cover;

  @media screen and (min-width: 1248.5px) {
    width: 298px;
    height: 608px;
  }

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    width: 176px;
    height: 360px;
  }

  @include phone {
    width: 195px;
    height: 400px;
  }

  &--transfer {
    @extend .phone;
    background-image: url("../../../public/images/phone-transfer.png");
  }

  &--invoices {
    @extend .phone;
    background-image: url("../../../public/images/phone-invoices.png");
  }

  &--bill {
    @extend .phone;
    background-image: url("../../../public/images/phone-bill.png");
  }

  &--start {
    @extend .phone;
    background-image: url("../../../public/images/phone-start.png");
  }
}