@import 'colors';
@import 'screens';

.text {
  font-weight: 500;
  font-feature-settings: 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on, 'ss01' on;
  color: $default;

  &--h1 {
      font-size: 96px;
      line-height: 115px;

  }
  &--h2 {
    font-size: 64px;
    line-height: 77px;

  }
  &--h3 {
    font-size: 56px;
    line-height: 67px;

  }
  &--h4 {
    font-size: 40px;
    line-height: 48px;


  }
  &--h5 {
    font-size: 32px;
    line-height: 38px;

  }
  &--h6 {
    font-size: 28px;
    line-height: 34px;

  }
  &--subtitle {
      font-size: 24px;
      line-height: 29px;

  }
  &--decorative-1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.5em;
  }
  &--decorative-2 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3em;
  }
  &--p {
    font-size: 18px;
    line-height: 32px;
    @media screen and (max-width: 1248px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &--caption {
    font-size: 16px;
    line-height: 24px;

    &--small {
      font-size: 14px;
    }

    &--mid {
      font-size: 18px;
    }

    &--big {
      font-size: 20px;
    }
  }

  &--gradient-purple {
    background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(359.9deg, #CAF6D9 -7.61%, rgba(202, 246, 217, 0) 111.71%), linear-gradient(354.35deg, #AD00FF -12.34%, rgba(220, 211, 248, 0) 100.15%), #C172E6;
  }
  &--gradient-pink {
    background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%), #C172E6;
  }
  &--gradient-pink-soft {
    background: linear-gradient(94.03deg, #FAE89E -11.8%, rgba(250, 232, 158, 0) 69.12%), linear-gradient(282.64deg, #FF9E98 16.28%, rgba(255, 207, 204, 0) 35.62%), linear-gradient(0deg, #DCD3F8 0%, rgba(220, 211, 248, 0) 75.56%), linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%), #C172E6;
  }
  &--gradient-v3 {
    background: radial-gradient(10.84% 56.09% at 62.07% 69.13%, #FAE89E 0%, rgba(250, 232, 158, 0) 100%), radial-gradient(9.11% 59.84% at 14.16% 58.26%, #FAE89E 0%, rgba(250, 232, 158, 0) 100%), radial-gradient(21.06% 190.43% at 100% 64.78%, #DCD3F8 0%, rgba(220, 211, 248, 0) 100%), radial-gradient(23.05% 155.65% at 86.72% 134.35%, #FF9E98 0%, rgba(255, 207, 204, 0) 100%), radial-gradient(12.51% 119.47% at 57.38% 0%, #CAF6D9 0%, rgba(202, 246, 217, 0) 100%), #E3D8E8;
  }
  &--gradient-v4 {
    background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(295.04deg, #CAF6D9 -17.52%, rgba(202, 246, 217, 0) 78.54%), linear-gradient(354.35deg, #AD00FF -12.34%, rgba(220, 211, 248, 0) 100.15%), #C172E6;;
  }

  &--gradient-white-stroke {
    background: linear-gradient(109.65deg, #FAE89E 2.17%, rgba(250, 232, 158, 0) 27.54%),
    linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%),
    linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%),
    linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%),
    linear-gradient(0deg, #C172E6, #C172E6);

    border: 0.8px solid;
    -webkit-background-clip: text;
    -webkit-text-stroke: 1px transparent;
    color: #000;
  }

  &--gradient-stroke {
    background: linear-gradient(109.65deg, #FAE89E 2.17%, rgba(250, 232, 158, 0) 27.54%),
    linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%),
    linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%),
    linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%),
    linear-gradient(0deg, #C172E6, #C172E6);

    -webkit-background-clip: text;
    -webkit-text-stroke: 1px transparent;
    color: #000;
  }

  &--gradient-dark-stroke {
    -webkit-background-clip: text;
    -webkit-text-stroke: 1px transparent;
    -webkit-text-stroke-color: rgba(55, 59, 73, 1);
    color: #000;
  }

  &--white {
    color: $white;
  }
  &--dark {
    color: $shade-dark;
  }

  &--xl {

    @media screen and (min-width: 1248.5px) {
      font-size: 332px;
      line-height: 399px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      font-size: 150px;
      line-height: 180px;
    }

    @include phone {
      font-size: 96px;
      line-height: 115px;
    }
  }

}

[class^="text--gradient-"] {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

[class^="text--"], [class*="text-"] {
  @extend .text;
}