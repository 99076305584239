.section-loyalty {
  position: relative;
  padding: 0 0 140px 0;
  overflow-x: hidden;
  margin-top: -240px;
  background: $black;

  .text {
    &--h1 {
      @include tablet {
        font-size: 56px;
        line-height: 67px;
      }
      @include phone {
        font-size: 64px;
        line-height: 77px;
      }
    }
    &--subtitle {
      @include tablet {
        font-size: 18px;
        line-height: 32px;
      }
      @include phone {
        font-size: 18px;
        line-height: 32px;
      }
    }   
    &--h3 {
      @include tablet {
        font-size: 40px;
        line-height: 48px;
      }
      @include phone {
        font-size: 40px;
        line-height: 48px;
      }
    }   
  }

  &__head {
    position: absolute;
    text-align: center;

    @media screen and (min-width: 1248.5px) {
      top: 20px;
      left: 0;
      width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      width: calc(100% - 48px);
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }

    @include phone {
      width: calc(100% - 48px);
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }

    .text--h3 {
      margin-bottom: 24px;
    }
    .text--p {
      max-width: 544px;
      margin: 0 auto 54px;
    }
  }

  &__text {
    margin-bottom: 20px;

    > div {
      &:last-child {
        align-self: flex-end;

        .text--subtitle {
          margin-bottom: 32px;
        }
      }
    }
  }

  &__face {
    width: 100%;
    border: none;
    pointer-events: none;

    @media screen and (min-width: 1248.5px) {
      height: 900px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      height: 700px;
    }

    @include phone {
      height: 600px;
    }

    img {
      width: 100%;
    }
  }

  .product-box {
    height: 470px;

    @include tablet {
      height: 302px;
    }

    @include phone {
      max-width: 342px;
      margin: 0 auto;
      height: 440px;
    }

    &__title {
      font-size: 40px;
      line-height: 48px;
      max-width: unset;
    }

    &--cashback {
      figure {
        position: absolute;
        width: 500px;
        top: 193px;
        left: 0;
        @include tablet {
          top: 120px;
          left: 50px;
          img {
            width: 245px;
          }
        }
        @include phone {
          top: 150px;
          left: -90px;
          img {
            width: 502px;
          }
        }
      }
    }
    
    &--exclusive {
      [class^="product"] {
        color: $white;
      }

      figure {
        position: absolute;
        top: 0;
        left: 0;

        @include tablet {
          img {
            width: 412px;
          }
        }
        @include phone {
          img {
            width: 502px;
          }
        }
      }
    }

    &--transactions {
      figure {
        position: absolute;
        bottom: 55px;
        left: 48px;
        @include tablet {
          bottom: 10px;
          img {
            width: 312px;
          }
        }
      }
    }
  }
}