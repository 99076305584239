@mixin flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@mixin inline-flex-center-start {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
@mixin flex-end-start {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
@mixin flex-start-end {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
@mixin flex-end-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
@mixin flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@mixin flex-start-center-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
@mixin flex-start-between-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@mixin position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

