.swiper {
  &__pagination {
    left: 0;
    bottom: -105px;
    width: 100%;
    height: 1px;
    background: $white-04;



    &.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      top: -1px;
      height: 3px;
      background: $white;
    }
  }

  &--help-boxes {
    .swiper-slide {
      width: 188px !important;
    }
  }

  &--team-members {
    .swiper-slide {
      width: 296px !important;
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  top: inherit;
  bottom: 0;

  + .swiper-wrapper {
    padding-bottom: 60px;
  }
}