@import "../Settings/mixins";
@import "../Settings/screens";

html, body {
  position: relative;
  width: 100%;
  min-height: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  // overflow-x: hidden;

  * {
    font-variant: normal;
    font-variant-numeric: normal;
  }
}


body {
  font-family: "NeueMontreal", sans-serif;
  margin: 0;
  background: #000000;

  &.overflow {
    overflow: hidden;
  }

  /** {
    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9BADBB;
      border-radius: 18px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #9BADBB;
    }
  }*/
}

.container {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    padding: 0 60px;
  }

  @include phone {
    padding: 0 24px;
  }
}

#root {
  width: 100%;
  min-height: 100%;
  //overflow-x: hidden;
}

figure, ul, li, h1,h2,h3,h4,h5,h6, p {
  margin: 0;
}
a {
  text-decoration: none;
}

.ant-form-item-explain-error {
  font-size: 12px;
  margin-bottom: 6px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

small {
  font-size: 100%;
}

.flex-center-start {
  @include flex-center-start;
}
.flex-center-end {
  width: 100%;
  @include flex-center-end;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text__title {
  font-size: 16px;
  font-weight: 500;
}

.block {
  display: block;
}

.grid-left {
  text-align: left;
}

.grid-center {
  text-align: center;
}

.grid-right {
  text-align: right;

  @include phone {
    text-align: inherit;
  }
}

.ant-row + .ant-row {
  margin-top: 60px;
}


@import "../Settings/colors";
@import "../Settings/typography";
@import "../Settings/icons";
@import "../Settings/animations";