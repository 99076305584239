@import '../../assets/styles/Settings/screens.scss';
@import '../../assets/styles/Settings/colors.scss';


.section-fees {


  .text-group {

    &:first-child {
      border-top: 0;
    }

    .text {
      @include desktop {
        font-size: 24px;
        line-height: 29px;
      }
      @include tablet {
        font-size: 18px;
        line-height: 32px;
      }
      @include phone {
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
        margin-top: 20px;
      }
    }
  }

  .button-group {

    button {

      @include phone {
        margin: 10px;
        min-width: 100px;
      }
    }
  }
}