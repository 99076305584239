$default: #CCCCCC;
$black: #000000;
$black-02: rgba(0, 0, 0, .2);
$black-03: rgba(0, 0, 0, .3);
$black-05: rgba(0, 0, 0, .5);
$black-06: #B2B2B2;
$abbey: #51545E;

$shade-dark: #1E2129;
$shade-dark-07: rgba(30, 33, 41, .7);
$shade-dark-23: rgba(30, 33, 41, .23);
$shade-dark-04: rgba(30, 33, 41, .4);
$shade-mid: #373B49;
$shade-mid-07: rgba(55, 59, 73, .7);
$shade-light: #F3F4F6;

$white: #FFFFFF;
$white-01: rgba(255, 255, 255, .1);
$white-02: rgba(255, 255, 255, .2);
$white-03: rgba(255, 255, 255, .3);
$white-04: rgba(255, 255, 255, .4);
$white-05: rgba(255, 255, 255, .5);
$white-07: rgba(255, 255, 255, .7);

$green: #CAF6D9;
$green-04: rgba(202, 246, 217, .4);

$aqua: #D0EFFB;
$aqua-04: rgba(208, 239, 251, .4);

$yellow: #FAE89E;
$yellow-04: rgba(250, 232, 158, .4);

$pink: #FDCAD6;
$pink-04: rgba(253, 202, 214, .4);

$silver: #C4C4C4;
$silver-04: rgba(196, 196, 196, .4);

$purple: #DCD3F8;
$purple-base: #6535E9;
$red: #FFCFCC;
$gray: #E1E3EA;
$shuttle-gray: #616675;


$gradient-pink: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%), #C172E6;
$gradient-orange: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(359.9deg, #CAF6D9 -7.61%, rgba(202, 246, 217, 0) 111.71%), #FF9E98;
$gradient-gray: radial-gradient(127.92% 121.25% at 50% 123.75%, #FFFFFF 0.01%, rgba(255, 255, 255, 0) 100%), radial-gradient(94.36% 101.63% at 100% 0%, rgba(180, 232, 255, 0.3) 14.06%, rgba(172, 230, 255, 0.3) 52.3%, rgba(255, 255, 255, 0) 100%), radial-gradient(103.16% 103.16% at 4.11% -3.16%, rgba(203, 186, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%), radial-gradient(101.6% 109.19% at 31.22% 14.98%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #0C0D10;