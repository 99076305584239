@import "src/assets/styles/Settings/mixins";
@import "src/assets/styles/Settings/screens";
@import "src/assets/styles/Settings/colors";


.scrollmagic-pin-spacer {
  height: 100%;

  .sticky {
    &.scroll-magic {
      /*top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      margin: auto !important;
      width: fit-content !important;
      height: fit-content !important;*/
      /*top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);

      transition: transform 0.0000001s*/
    }
  }

  + .scrollmagic-pin-spacer {
    .sticky {
      opacity: 0;
      transition: opacity .1s ease;

      &.scroll-magic {
        opacity: 1;
        transition: opacity .5s ease;
      }
    }
  }
}

.pinContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .test {
    position: relative;

    > div {
      position: absolute;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      width: 100%;
      min-width: calc(1280px - 100px);
      @include tablet {
        min-width: calc(768px - 125px);
      }
      @include phone {
        width: 100%;
        min-width:390px;
      }
    }
  }
}

.panel {
  width: 100%;
  background: red;
}


.section {
  &-sequence {
    overflow-x: hidden;
    position: relative;
    min-height: 825px;
    @media screen and (min-width: 1248.5px) {
      margin-bottom: 300px;
    }
    &__cards {
      width: 100%;
      border: none;
      pointer-events: none;
  
      @media screen and (min-width: 1248.5px) {
        height: 100vh;
      }
  
      @media screen and (min-width: 768px) and (max-width: 1248px) {
        height: 700px;
      }
  
      @include phone {
        height: 600px;
      }
  
      iframe {
        width: 100%;
      }
    }

    > div {
      display: block;
      width: 100%;
      height: 100%;
    }

    iframe {
      display: block;
      width: 100%;
      opacity: 1;
      transition: transform .5s ease;
    }

    &.scale {
      iframe {
        transform: scale(0.7) translateY(-160px);
      }
    }
  }

  &-banner {
    .hero-card {
      &-left, &-right {
        position: absolute;
        display: none;


        img {
          width: 100%;
        }
      }

      &-left {
        width: 700px;
        left: calc(50% - 200px);

        transform: translateX(-50%);
        top: 240px;
        z-index: 1;
      }
      &-right {
        width: 600px;
        left: calc(50% + 200px);
        transform: translateX(-50%);
        top: 220px;
        z-index: 0;
      }
    }

    > .container {
      position: relative;
      z-index: 3;
    }
  }

  &-banner, &-second {

    .text {
      &--h1{
        @include tablet {
          font-size: 64px;
          line-height: 77px;
        }
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
  }
  &-phone {
    position: relative;
    .scrollmagic-pin-spacer {
      &:nth-child(even) {
        .grid {
          @media screen and (min-width: 1248.5px) {
            grid-template-areas: 'phone text';
          }

          .section-phone__description {
            @media screen and (min-width: 1248.5px) {
              grid-column: 10/13;
              text-align: right;
            }

            .button-group, .button--link {
              @media screen and (min-width: 1248.5px) {
                justify-content: flex-end;
              }

              @media screen and (min-width: 768px) and (max-width: 1248px) {
                justify-content: flex-start;
              }
            }


          }
        }
      }
    }



    &.transform {
      transform: translateY(-100vh);
      transition: transform 2s ease;
    }

    .banner-img-2 {
      position: absolute;
      top: -100vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;


      @media screen and (max-width: 1248px) {
        display: none;
      }
    }

    .text {
      &--h3 {
        @include tablet {
          font-size: 32px;
          line-height: 38px;
        }
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
      &--p {
        @include tablet {
          font-size: 16px;
          line-height: 24px;
        }
        @include phone {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .grid {
      position: relative;
      display: grid;

      @media screen and (min-width: 1248.5px) {
        grid-template-areas: 'text phone';
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 24px;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        grid-template-areas: 'phone text';
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 24px;
      }

      @include phone {
        grid-template-areas: 'phone'
                            'text';
        grid-row-gap: 52px;

      }

      + .grid {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        /*@media screen and (min-width: 1248.5px) {
          margin-top: 190px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          margin-top: 128px;
        }

        @include phone {
          margin-top: 100px;
        }*/
      }

      .section-phone__description {
        grid-area: text;
        text-align: left;

        @media screen and (min-width: 1248.5px) {
          grid-column: span 3;
          align-self: center;
        }

        @media screen and (max-width: 1248px) {
          align-self: flex-end;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          grid-column: 7/13;
        }

        @include phone {
          grid-column: span 12;
          text-align: center;
        }

        .text--h3 {
          @media screen and (min-width: 1248.5px) {
            margin-bottom: 22px;
          }

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            margin-bottom: 16px;
          }

          @include phone {
            margin-bottom: 12px;
          }
        }
        .text--p {
          @media screen and (min-width: 1248.5px) {
            margin-bottom: 22px;
          }

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            margin-bottom: 16px;
          }

          @include phone {
            max-width: 342px;
            margin: 0 auto 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .button--link {
          justify-content: flex-start;

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            height: 32px;
          }

          @include phone {
            justify-content: center;
          }
        }
        .button--primary {
          padding: 0;
        }
      }

      [class^="accessory-"] {
        img {
          width: 100%;
          display: block;
        }
      }

      [class^="phone-"] {
        display: grid;
        grid-area: phone;
        margin: 0 auto;

        @media screen and (min-width: 1248.5px) {
          grid-column: 5/9;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          grid-column: span 6;
        }

        @include phone {
          grid-column: span 12;
        }

        .message-box {
          position: absolute;

          &.first {
            @media screen and (min-width: 1248.5px) {
              top: 154px;
              left: -18px;
            }

            @media screen and (max-width: 1248px) {
              top: 78px;
              left: -13px;
            }
          }
          &.second {
            @media screen and (min-width: 1248.5px) {
              bottom: 118px;
              left: -133px;
            }
            @include phone {
              bottom: 128px;
              left: 133px;
            }

            @media screen and (max-width: 1248px) {
              bottom: 64px;
              left: -56px;
            }
          }

          &.bill {
            @media screen and (min-width: 1248.5px) {
              bottom: 74px;
              left: -18px;
            }

            @media screen and (max-width: 1248px) {
              bottom: 37px;
              left: -13px;
            }

            .message-box {
              &__middle {
                > div {
                  @include flex-space-between;
                  width: 100%;

                  @media screen and (min-width: 1248.5px) {
                    margin-bottom: 22px;
                  }

                  @media screen and (max-width: 1248px) {
                    margin-bottom: 12px;
                  }

                  .user-portrait {
                    width: 50%;

                    + .user-portrait {
                      margin-left: 16px;
                    }
                  }
                }

                .button {
                  width: 100%;

                  @media screen and (max-width: 1248px) {
                    height: 28px;
                    min-width: inherit;
                  }

                  i {
                    @media screen and (max-width: 1248px) {
                      font-size: 12px;
                    }
                  }

                  span {
                    @media screen and (min-width: 1248.5px) {

                    }

                    @media screen and (max-width: 1248px) {
                      font-size: 8px;
                      line-height: 11px;
                      margin-left: 6px;
                    }
                  }
                }
              }
            }
          }

          &.invoices {
            @media screen and (min-width: 1248.5px) {
              top: 214px;
              left: -18px;
            }

            @media screen and (max-width: 1248px) {
              bottom: 142px;
              left: -13px;
            }

            .message-box__middle {
              .action-menu {
                & + .action-menu {
                  @media screen and (min-width: 1248.5px) {
                    margin-top: 6px;
                  }

                  @media screen and (max-width: 1248px) {
                    margin-top: 3px;
                  }
                }
              }
            }
          }

          &.start {
            @media screen and (min-width: 1248.5px) {
              bottom: 158px;
              left: -18px;
            }

            @media screen and (max-width: 1248px) {
              bottom: 78px;
              left: -13px;
            }

            .message-box__right {
              i {
                @include flex-center;
                background: $shade-dark;
                box-sizing: border-box;
                color: $white;

                @media screen and (min-width: 1248.5px) {
                  padding: 2px 12px;
                  width: 44px;
                  height: 26px;
                  border-radius: 70px;
                }

                @media screen and (max-width: 1248px) {
                  padding: 2px 6px;
                  width: 25px;
                  height: 14px;
                  border-radius: 38px;
                }
              }
            }
          }
        }
        .accessory-1 {
          @include flex-center;
          position: absolute;
          background: #ff406C;
          border-radius: 50%;

          @media screen and (min-width: 1248.5px) {
            bottom: 0;
            left: 125px;
            width: 80px;
            height: 80px;
          }

          @media screen and (max-width: 1248px) {
            bottom: -100px;
            right: 128px;
            width: 48px;
            height: 48px;
          }

          i {
            color: $white;

            @media screen and (min-width: 1248.5px) {
              font-size: 58px;
            }

            @media screen and (max-width: 1248px) {
              font-size: 28px;
            }
          }
        }
        .accessory-2 {
          @include flex-center;
          position: absolute;
          top: 130px;
          left: -1;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          @media screen and (min-width: 1248.5px) {
            position: absolute;
            top: 10px;
            left: -50px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          @include phone {
            left: -80px;
          }
        }
      }

      &:nth-child(even) {
        @media screen and (min-width: 1248.5px) {
          grid-template-areas: 'phone text';
        }

        .section-phone__description {
          @media screen and (min-width: 1248.5px) {
            grid-column: 10/13;
            text-align: right;
          }

          .button-group, .button--link {
            @media screen and (min-width: 1248.5px) {
              justify-content: flex-end;
            }

            @media screen and (min-width: 768px) and (max-width: 1248px) {
              justify-content: flex-start;
            }
          }


        }
      }

      /*&:last-child {
        .section-phone__description {
          @media screen and (min-width: 1248.5px) {
            grid-column: 9/13;
          }
        }

        .button-group {

          .button {
            & + .button {
              margin-left: 20px;
            }
          }
        }
      }*/

      &.transfer {
        .chat-messages-wrapper {
          position: absolute;

          @media screen and (min-width: 1248.5px) {
            top: 4px;
            left: calc(100% - 56px);
            width: 100%;
            max-width: 305px;
          }

          @media screen and (max-width: 1248px) {
            top: 2px;
            left: calc(100% - 108px);
            width: 188px;
          }
        }
      }

      &.bill {
        .chat-messages-wrapper {
          position: absolute;
          top: 14px;
          left: -100%;
          max-width: 288px;
          width: 100%;
        }

        .accessory-3 {
          @include flex-center;
          position: absolute;
          top: 200px;
          left: 8px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: $green;
        }
        .accessory-4 {
          text-align: center;
          position: absolute;
          top: -25px;
          right: -95px;
          z-index: 11;

          @include phone {
            right: -75px;
          }

          span {
            @include flex-center;
            position: relative;
            background: $pink;
            color: $shade-dark;

            @media screen and (min-width: 1248.5px) {
              width: 148px;
              height: 54px;
              border-radius: 8px;
              margin-bottom: 20px;
              font-size: 16px;
            }

            @media screen and (max-width: 1248px) {
              width: 74px;
              height: 38px;
              border-radius: 4px;
              margin-bottom: 16px;
              font-size: 8px;
            }

            .triangle {
              position: absolute;
              bottom: -9px;
              left: 50%;
              transform: translateX(-50%);

              @media screen and (max-width: 1248px) {
                width: 20px;
              }

              &__inner {
                background: $pink;

                @media screen and (max-width: 1248px) {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          figure {
            margin: 0 auto;

            @media screen and (min-width: 1248.5px) {
              width: 80px;
              height: 80px;
            }

            @media screen and (max-width: 1248px) {
              width: 40px;
              height: 40px;
            }

            img {
              width: 100%;
            }
          }
        }
      }

      &.invoices {
        .chat-messages-wrapper {
          position: absolute;
          top: 40px;
          left: calc(100% + 48px);
          width: 100%;
          max-width: 328px;

          @media screen and (min-width: 1248.5px) {
            top: 40px;
            left: calc(100% + 48px);
          }

          @media screen and (max-width: 1248px) {
            top: 24px;
            left: calc(100% + -74px);
          }
        }

        .message-box__middle {
          .action-menu {
            & + .action-menu {
              margin-top: 6px;
            }
          }
        }

        .accessory-5 {
          position: absolute;
          top: -10px;
          left: -130px;

          @media screen and (min-width: 1248.5px) {
            width: 100px;
            height: 100px;
          }
          @include phone {
            left: -70px;
          }

          i {
            @media screen and (max-width: 1248px) {
              width: 64px;
              height: 64px;
              font-size: 30px;
            }
          }
        }
        .accessory-6 {
          @include flex-center-start;
          position: absolute;
          background: transparent;

          @media screen and (min-width: 1248.5px) {
            width: 100%;
            left: calc(100% + 48px);
            bottom: 96px;
            padding-left: 20px;
          }

          @media screen and (max-width: 1248px) {
            left: calc(-90% + 130px);
            bottom: 6px;
            z-index: -1;
          }

          > div  {
            > i {
              position: relative;
            }

            @media screen and (max-width: 1248px) {
              width: 32px;
              height: 32px;
              font-size: 12px;
            }

            @for $i from 1 through 4 {
              &:nth-child(#{$i}) {
                > i {
                  @media screen and (min-width: 1248.5px) {
                    left: calc( -16px * #{$i} );
                  }
                  @media screen and (max-width: 1248px) {
                    left: calc( -10px * #{$i} );
                  }
                }
              }
            }
          }
        }
      }

      &.start {
        .chat-messages-wrapper {
          position: absolute;
          @media screen and (min-width: 1248.5px) {
            bottom: 200px;
            left: -272px;
            max-width: 228px;
            width: 100%;
          }

          @media screen and (max-width: 1248px) {
            bottom: 110px;
            left: calc(-100% - -52px);
            max-width: 228px;
            width: 100%;
          }

          > div {
            @for $i from 1 through 6 {
              &:nth-last-child(#{$i}) {
                .chat-message__item-wrapper {
                  opacity: #{calc($i/10)};
                  box-shadow: 0 24px 45px rgba(0, 0, 0, 0.25), 0 1px 3px rgba(146, 150, 165, 0.12);
                }
              }
            }
          }

        }
        .accessory-7 {
          position: absolute;

          @media screen and (min-width: 1248.5px) {
            top: 0;
            left: 302px;
            width: 89px;
            height: 89px;
          }

          @media screen and (max-width: 1248px) {
            top: 0;
            left: 0;
            width: 44px;
            height: 44px;
          }
        }
        .accessory-8 {
          @media screen and (min-width: 1248.5px) {
            position: absolute;
            bottom: 120px;
            left: 0;
            width: 89px;
            height: 89px;
          }
          @media screen and (max-width: 1248px) {
            display: none;
          }
        }
        .accessory-9 {
          @media screen and (min-width: 1248.5px) {
            position: absolute;
            top: 30px;
            left: 60px;
            width: 42px;
            height: 42px;
          }

          @media screen and (max-width: 1248px) {
            display: none;
          }
        }
        .accessory-10 {
          position: absolute;

          @media screen and (min-width: 1248.5px) {
            bottom: 24px;
            left: 255px;
            width: 42px;
            height: 42px;
          }

          @media screen and (max-width: 1248px) {
            bottom: 20px;
            left: 0;
            width: 21px;
            height: 21px;
          }
        }
      }

      .chat-messages-wrapper {
        div + div {
          margin-top: 10px;
        }
      }
    }
  }

  &--control{
  background: $shade-light;
  padding-top:200px ;

    p {
      + button {
        margin-top: 30px;
      }
    }

    .row-control {
      margin-bottom:264px;
    }

    
  }

  &-second {
    padding: 268px 0;
    margin-top: 250px;
    text-align: center;
  }
  &-loyalty {
    overflow-x: hidden;
  }
  &-financial {
    overflow-x: hidden;
  }
  &-questions {
    overflow-x: hidden;
  }
  &-contact {
    overflow-x: hidden;
  }

  &-business {
    overflow-x: hidden;
    .text {

      &--h1 {
        @include tablet {
          font-size: 56px;
          line-height: 67px;
        }
        @include phone {
          font-size: 64px;
          line-height: 77px;
        }
      }
      &--subtitle {

        @include tablet {
          font-size: 16px;
          line-height: 24px;
        }
        @include phone {
          font-size: 18px;
          line-height: 32px;
        }
      }
      &--h3 {
        @include tablet {
          font-size: 32px;
          line-height: 38px;
        }
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }
      &--h4 {
        @include tablet {
          font-size: 16px;
          line-height: 24px;
        }
        @include phone {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
}

.footer {
  overflow-x: hidden;
}


