.ant-row {
  &--swiper {
    @media screen and (min-width: 768px) and (max-width: 1248px) {
      width: calc(100% + 20px);
      margin-left: -10px;
    }

    @include phone {
      width: calc(100% + 48px);
      margin-left: -24px;
    }
  }
}