.section-banner {
  margin-top: 200px;

  .text--h1 {
    position: relative;
    text-align: center;
    margin: 0 auto 268px;
  }

  .banner-img-1 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}
