.image-circle {
  @include flex-center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  box-sizing: border-box;

  @media screen and (min-width: 1248.5px) {
    padding: 8px;
  }

  @media screen and (max-width: 1248px) {
    padding: 4px;
  }

  &--small {
    width: 38px;
    height: 38px;
    padding: 4px;
  }

  &--green {
    background: $green;
  }
  &--pink {
    background: $pink;
  }
  &--aqua {
    background: $aqua;
  }
  &--yellow {
    background: $yellow;
  }
  &--purple {
    background: $purple;
  }

  img {
    width: 100%;
    display: block;
  }
}