.section-questions {
  padding: 200px 0 100px;

  .ant-row {
    .ant-col {
      &:first-child {
        @media screen and (max-width: 991.8px){
          margin-bottom: 64px;
        }
      }
    }
  }
}