.message-box {
  $message-box: &;
  position: relative;

  @media screen and (min-width: 1248.5px) {
    width: 332px;
  }

  @media screen and (max-width: 1248px) {
    width: 202px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $white;
    border-bottom: 1.47005px solid $gray;
    box-shadow: 0 22.3837px 41.0368px rgba(0, 0, 0, 0.25);
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 10;

    @media screen and (min-width: 1248.5px) {
      padding: 20px 22px;
      border-radius: 25.743px;
      margin-top:-20px;
    }

    @media screen and (max-width: 1248px) {
      padding: 10px 12px;
      border-radius: 12px;
    }

    #{ $message-box }__icon {
      @include flex-center;
      background: $shade-dark;

      @media screen and (min-width: 1248.5px) {
        width: 52px;
        height: 52px;
        border-radius: 10px;
      }

      @media screen and (max-width: 1248px) {
        width: 38px;
        height: 38px;
        border-radius: 8px;
      }

      i {
        color: $white;

        @media screen and (min-width: 1248.5px) {
          font-size: 32px;
        }

        @media screen and (max-width: 1248px) {
          font-size: 12px;
        }
      }

      &--circle {
        @extend .message-box__icon;
        border-radius: 50%;
        background: $white;
        border: 1px solid $gray;

        @media screen and (min-width: 1248.5px) {
          width: 32px;
          height: 32px;
        }

        @media screen and (max-width: 1248px) {
          width: 16px;
          height: 16px;
        }

        i {
          color: $black;

          @media screen and (min-width: 1248.5px) {
            font-size: 20px;
          }

          @media screen and (max-width: 1248px) {
            font-size: 10px;
          }
        }
      }
    }

    #{ $message-box }__text {
      display: block;
      font-weight: 500;
      font-feature-settings: 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on, 'ss01' on;
      color: $shade-dark;

      @media screen and (min-width: 1248.5px) {
        font-size: 19px;
        line-height: 20px;
      }

      @media screen and (max-width: 1248px) {
        font-size: 10px;
        line-height: 10px;
      }
    }

    small {
      @include flex-center-start;
      font-weight: 400;
      letter-spacing: 0.02em;
      font-feature-settings: 'salt' on, 'ss01' on, 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on;
      color: $shuttle-gray;

      @media screen and (min-width: 1248.5px) {
        font-size: 11px;
        line-height: 19px;
      }

      @media screen and (max-width: 1248px) {
        font-size: 5.63128px;
        line-height: 10px;
      }

      & + small {
        margin-left: 3px;
      }
    }

    #{ $message-box }__left {
      @media screen and (min-width: 1248.5px) {
        margin-right: 8px;
      }

      @media screen and (max-width: 1248px) {
        margin-right: 10px;
      }
    }

    #{ $message-box }__right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @media screen and (min-width: 1248.5px) {
        right: 22px;
      }

      @media screen and (max-width: 1248px) {
        right: 12px;
      }

      > i {
        color: $black;

        @media screen and (min-width: 1248.5px) {
          font-size: 25px;
        }

        @media screen and (max-width: 1248px) {
          font-size: 13px;
        }
      }
    }

    #{ $message-box }__middle {
      //@include flex-start-center-column;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    background: $white;
    opacity: 0.5;
    box-shadow: 0 26.8604px 49.2441px rgba(0, 0, 0, 0.25);
    border-radius: 24.1341px;
    z-index: 9;

    @media screen and (min-width: 1248.5px) {
      width: calc(100% - 60px);
      bottom: -12px;
    }

    @media screen and (max-width: 1248px) {
      width: calc(100% - 30px);
      bottom: -6px;
    }
  }

  &--small {
    @media screen and (min-width: 1248.5px) {
      width: 246px;
    }

    @media screen and (max-width: 1248px) {
      width: 124px;
    }

    &:before {
      @media screen and (min-width: 1248.5px) {
        width: calc(100% - 20px);
        bottom: -6px;
      }

      @media screen and (max-width: 1248px) {
        width: calc(100% - 10px);
        bottom: -3px;
      }
    }

    #{ $message-box }-wrapper {
      @media screen and (min-width: 1248.5px) {
        padding: 9px;
        border-radius: 8px;
      }

      @media screen and (max-width: 1248px) {
        padding: 4px;
        border-radius: 4px;
      }

      #{ $message-box }__left {
        @media screen and (min-width: 1248.5px) {
          margin-right: 12px;
        }

        @media screen and (max-width: 1248px) {
          margin-right: 6px;
        }
      }
      #{ $message-box }__right {
        @media screen and (min-width: 1248.5px) {
          right: 9px;
        }

        @media screen and (max-width: 1248px) {
          right: 4px;
        }
      }

      #{ $message-box }__text {
        display: block;
        letter-spacing: 0.02em;

        @media screen and (min-width: 1248.5px) {
          font-size: 12px;
          line-height: 19px;
        }

        @media screen and (max-width: 1248px) {
          font-size: 6px;
          line-height: 10px;
        }
      }
    }
  }

  &--only {
    #{ $message-box }-wrapper {
      width: 100%;

      #{ $message-box }__middle {
        width: 100%;
      }
    }
  }

  * {
    z-index: 10;
  }
}