.footer {
  $footer: &;
  position: relative;
  padding-bottom: 82px;
  overflow-x: hidden;

  > .container {
    display: grid;
    grid-template-areas:
          'top-left top-left top-right'
          'middle-left middle-left middle-right'
          'bottom bottom bottom'
          'copyright copyright copyright';

    @media screen and (min-width: 1248.5px) {

    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      grid-template-areas:
          'top-left top-left top-right'
          'middle-left bottom middle-right'
          'copyright copyright copyright';
    }

    @include phone {
      grid-template-areas:
          'top-left'
          'middle-left'
          'top-right'
          'middle-right'
          'bottom'
          'copyright';
      grid-template-columns: 100%;
    }
  }

  .footer__top-left {
    grid-area: top-left;
    margin-bottom: 40px;

    figure {
      @media screen and (min-width: 1248.5px) {
        margin-bottom: 66px;
        
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        margin-bottom: 46px;
      }

      @include phone {
        text-align: center;
        //margin-bottom: 54px;
      }
    }

    .text--caption {
      display: block;
      margin-bottom: 30px;
      color: $white-04;

      @include phone {
        text-align: center;
      }
    }

    .button-group {
      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }

      @include phone {
        justify-content: center;
      }

      button + button {
        @media screen and (min-width: 768px) {
          margin-left: 6px;
        }

        @include phone {
          margin-left: 22px;
        }
      }
    }
  }

  .footer__top-right {
    grid-area: top-right;

    @media screen and (min-width: 768px) {
      justify-self: flex-end;
    }

    @include phone {
      width: 100%;
      justify-self: center;
    }

    .button {
      @media screen and (min-width: 1248.5px) {
        margin-bottom: 50px;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        margin-bottom: 32px;
      }

      @include phone {
        margin-bottom: 80px;
      }
    }

    .select {
      @include flex-center-end;
      max-width: 62px;
      margin: 0 0 0 auto;

      .ant-select-selection-item, .ant-select-selector {
        padding-right: 0 !important;
      }

      @include phone {
        position: absolute;
        top: 0;
        right: 24px;
      }
    }
  }

  .footer__middle-left {
    grid-area: middle-left;
    @include flex-start-start;

    @media screen and (min-width: 1248.5px) {
    }

    @media screen and (max-width: 1248px) {
      flex-direction: column;
    }

    @include phone {

    }

    > div {
      @include phone {
        width: 100%;
        text-align: center;
      }

      span, a {
        color: $white;
      }

      span {
        display: block;

        @media screen and (min-width: 768px) {
          margin-bottom: 20px;
        }

        @include phone {
          margin-bottom: 40px;
          text-align: center;
        }
      }

      a, .footer__link {
        @media screen and (min-width: 768px) {
          display: block;
        }

        @include phone {
          display: none;
        }

        + a, + span {
          @media screen and (min-width: 768px) {
            margin-top: 10px;
          }
        }
      }

      + div {
        @media screen and (min-width: 1248.5px) {
          margin-left: 60px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          margin-top: 12px;
        }

        @include phone {

        }
      }
    }

  }

  .footer__middle-right {
    grid-area: middle-right;

    @media screen and (min-width: 1248.5px) {
      @include flex-start-end;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      @include flex-start-start;
      flex-direction: column;
    }

    @include phone {
      @include flex-center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      width: 100%;
      height: auto;
      figure {

        img {

        }
      }

    }

    figure {
      + figure {
        @media screen and (min-width: 1248.5px) {
          margin-left: 36px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          margin-top: 36px;
        }
        @include phone {
          margin-left: 30px;
        }
      }
    }
  }

  .footer__bottom {
    grid-area: bottom;
    position: relative;

    @media screen and (min-width: 1248.5px) {
      @include flex-space-between;
      //margin-top: 86px;
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      @include flex-start-start;
      flex-direction: column;
    }

    @include phone {

    }

    &__links {
      @media screen and (min-width: 768px) {
      }
      @include phone {
        @include flex-center;
        flex-direction: column;
      }

      .text--caption {
        color: $white-04;

        + .text--caption {
          @media screen and (min-width: 1248.5px) {
            margin-left: 40px;
          }

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            display: block;
            margin-top: 12px;
          }

          @include phone {
            margin-top: 24px;
          }
        }
      }
    }

    &__social {
      @include flex-center;

      a {
        i {
          &:before {
            color: $white-05;
          }
        }
        + a {
          margin-left: 12px;
        }
      }
    }
  }

  &__copyright {
    position: relative;
    grid-area: copyright;
    display: block;
    text-align: center;

    @media screen and (min-width: 1248.5px) {
      padding-top: 66px;
      margin-top: 48px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      padding-top: 32px;
      margin-top: 32px;
    }

    @include phone {
      margin-top: 40px;
      padding-top: 40px;
    }

    &:before {
      content: '';
      background: $silver-04;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}