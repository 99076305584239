@import '../../assets/styles/Settings/screens.scss';
@import '../../assets/styles/Settings/colors.scss';

.section-policies {
    background: $shade-light;
    padding: 100px 0 300px;
    position: relative;

    .policies-margin-b {
        margin-bottom: 70px;

        p {
            margin-bottom: 70px;
        }
    }

    .ant-tabs-tab-btn {

        margin: 0 !important;

        span {
            color: $shade-dark;
        }
    }
    .ant-tabs-nav {

        @include phone {
            display: none !important ;
        }
    }

}
