.help-box {
  @include flex-center;
  flex-direction: column;
  padding: 20px;
  background: $white;
  border-radius: 24px;
  width: 188px;
  height: 200px;

  &__image {
    width: 80px;
    height: 80px;
    margin-bottom: 22px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    color: $shade-dark;
  }
}