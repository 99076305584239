.user-portrait {
  background: $white;
  border: 1.15731px solid $gray;
  border-radius: 9px;

  @media screen and (min-width: 1248.5px) {
    padding: 22px;
  }

  @media screen and (max-width: 1248px) {
    padding: 12px 8px;
  }

  > * {
    margin: 0 auto;
  }

  .circle {
    border-radius: 50%;
    background: $shade-light;

    @media screen and (min-width: 1248.5px) {
      width: 46px;
      height: 46px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1248px) {
      width: 24px;
      height: 24px;
      margin-bottom: 10px;
    }
  }
  .line {
    background: $shade-light;

    @media screen and (min-width: 1248.5px) {
      width: 100px;
      height: 10px;
    }

    @media screen and (max-width: 1248px) {
      width: 50px;
      height: 5px;
    }

    &--second {
      @extend .line;

      @media screen and (min-width: 1248.5px) {
        width: calc(100% - 26px);
      }

      @media screen and (max-width: 1248px) {
        width: calc(100% - 12px);
      }

    }

    + .line {
      margin-top: 6px;
    }
  }
}