@import '../../assets/styles/Settings/screens.scss';
@import '../../assets/styles/Settings/colors.scss';

.section-security {
    background: $shade-light;
    padding: 100px 0 300px;
    position: relative;
      &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 884px;
      left: 0;
      bottom: 0;
      background: radial-gradient(89.94% 75.19% at 100% 106.82%, #CAF6D9 0%, rgba(202, 246, 217, 0) 100%), radial-gradient(80.8% 67.55% at 0% 100%, #FF9E98 0%, rgba(255, 207, 204, 0) 100%), radial-gradient(165.81% 84.32% at 100% 100%, #AD00FF 0%, rgba(220, 211, 248, 0) 100%), #F3F4F6;
    }

    .row-title {
        .text {

            &--h3 {
                @include tablet {
                    font-size: 64px;
                    line-height: 77px;
                }
                @include phone {
                    font-size: 40px;
                    line-height: 48px;
                }
            }
            &--h4 {
                @include tablet {
                    font-size: 32px;
                    line-height: 38px;
                }
                @include phone {
                    font-size: 24px;
                    line-height: 29px;
                }
            }
        }
    }
  
    h1 {
      margin-bottom: 100px;
    }

    .product-box{
        padding: 0;

        .button {
            position: absolute;
            left: 80px;
            bottom: 65px;
            @include tablet {
             left: 15px;
             top: 120px;   
            }
            @include phone {
                left: 15px;
                top: 200px;  
            }
        }

        &__title {
            padding: 50px ;
            @include tablet {
                padding: 24px;
            }
            @include phone {
                padding: 53px 24px;
            }
        }

        &--cashback {
            height: 645px;
            min-width:342px;
            .text {
                &--h3{
                 
                    @include tablet {
                        font-size: 28px;
                        line-height: 34px;
                    }
                    @include phone {
                        font-size: 40px;
                        line-height: 48px;
                    }
                }
            }
            @include tablet {
                height: 364px;
            }
            @include phone {
                height: 583px;
                margin-bottom:100px ;
            }
            figure{
                max-width: 564px;
                position: absolute;
                bottom: -120px;

                @include tablet {
                    max-width: 343px;
                    bottom: -65px;
                }
                @include phone {
                    min-width: 463px;
                    bottom: -90px;
                    left: -60px;
                }
            }
            
        }

        &--chain {
            height: 645px;
            min-width:342px ;
            .text {
                &--h3{
                 
                    @include tablet {
                        font-size: 28px;
                        line-height: 34px;
                    }
                    @include phone {
                        font-size: 40px;
                        line-height: 48px;
                    }
                }
            }
            @include tablet {
                height: 364px;
            }
            @include phone {
                height: 583px;
                max-width: 502px;
            }
            figure {
              position: absolute;
              bottom: -150px;
              right:-180px;
              @include tablet {
                max-width: 339px;
                bottom: -80px;
                right: -80px;
              }
              @include phone {
                width: 634px;
                max-width: 634px;
                bottom: -200px;
                right: -220px;
              }
            }
          }



    }
  }

  .section--control {
    padding-bottom: 236px;

    .text {
        &--h3 {
            @include tablet {
                font-size: 40px;
                line-height: 48px;
            }
            @include phone {
                font-size: 40px;
                line-height: 48px;
            }
        }
        &--h4 {
            @include tablet {
                font-size: 28px;
                line-height: 34px;
            }
            @include phone {
                font-size: 40px;
                line-height: 48px;
            }
        }
        &--subtitle {
            @include tablet {
                font-size: 18px;
                line-height: 32px;
            }

        }
    }

    .row-control {

        .text--h3 {
            margin-bottom: 50px;
        }
    }
    .product-box {
        padding: 20px;
        height: 520px;

        @include tablet {
            height: 364px ;
        }
        @include phone {
            height: 374px ;
        }


      &--notification {
        
        figure {
          max-width: 685px;
          position: absolute;
          left: -185px;
          top: 114px;
          @include tablet {
            max-width: 408px;
            left: -106px ;
          }
          @include phone {
            min-width: 450px;
            left: -120px;
          }
        }
      }
      &--manage {
        background: $purple;
        figure {
          max-width: 311px;
          position: absolute;
          top: 220px;
          left: 46px;
          @include tablet {
            width: 249px;
            top: 150px;
            left: 32px;
          }
          @include phone {
            width: 278px;
            top: 216px;
            left: 32px;
          }
        }
      }

      &--identity {

          p {
            font-size: 40px;
            font-weight: 500;
            position: absolute;
            top: 350px ;
            left: 44px;
            @include tablet {
                font-size: 28px;
                line-height: 34px;
                top: 250px;
                left: 24px;
            }
            @include phone {
                font-size: 40px;
                line-height: 48px;
                top: 236px;
                left: 32px;
            }
          }

          figure {
            width: 228px;
            position: absolute;
            top: 119px;
            left: 86px;
            @include tablet {
            width: 156px;
            top: 73px;
            left: 78px;
            }
            @include phone {
                width: 172px;
                top: 36px;
                left: 85px;
            }
          }

      }

      &--steps {
        background: $black;
        figure {
          position: absolute;
          width: 296px;
          top:175px;
          left: 133px;
          @include tablet {
            width: 205px;
            top: 138px;
            left: 67px;
            
          }
          @include phone {
            width: 198px;
            top: 152px;
            left: 96px;
          }
        }
      }
    }
  }




