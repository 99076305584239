
.table--custom {
  .head {
    @include flex-space-between;
    padding-bottom: 64px;

    span {
      font-size: 24px;
      line-height: 40px;
      color: $black-05;
      text-align: center;

      &:first-child {
        width: 100%;
        text-align: left;
      }

      + span {
        min-width: 220px;
        margin: 0 auto;

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          min-width: 150px;
        }
      }
    }
  }

  .body {

    .cards-images {
      @include flex-space-between;

      div {
        text-align: center;

        &:first-child {
          width: 100%;
        }

        + div {
          min-width: 290px;
          margin-left: 40px;
        }


        &.cards-images__standart {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("../../../../public/images/compare-card-bg.png");
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            width: 294px;
            height: 1815px;
            z-index: -1;

            @media screen and (max-width: 1248px) {
              width: 100%;
              left: 50%;
              transform: translateX(-50%);
              background-size: cover;
            }
          }

          figure {
            margin-top: -160px;
          }

          > span {
            display: block;

            &.text--caption {
              color: $white-05;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .text-group {
      @include flex-space-between;
      padding: 64px 0;


      @include phone {
        display: block;
      }

      + .text-group {
        border-top: 1px solid $black-02;
      }

      span {
        display: block;
        text-align: center;
        color: $black;

        &:first-child {
          @media screen and (min-width: 1248.5px) {
            width: 100%;
            text-align: left;
          }

          @include phone {
            width: 100%;
          }
        }

        + span {
          min-width: 220px;
          margin: 0 auto;

          @media screen and (min-width: 768px) and (max-width: 1024px) {
            min-width: 150px;
          }
        }
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &--white {
    @extend .table--custom;

    .head {
      span {
        color: $white;

        + span {
          @include desktop {
            min-width: 294px;
            margin: 0 0 0 30px;
          }
        }

      }
    }
    .button-group {
      .button {
        @media screen and (max-width: 1248px) {
          min-width: 100px;
        }
      }
    }
    .body {
      .text-group {
        @media screen and (max-width: 1248px) {
          display: block;
        }

        + .text-group {
          border-color: $white-02;
        }

        span {
          color: $white;

          + span {
            @include desktop {
              min-width: 294px;
              margin: 0 0 0 30px;
            }
          }
        }
      }
    }
  }
}