.input {
  $self: &;
  position: relative;
  width: 100%;

  input, textarea {
    width: 100%;
    height: 72px;
    display: block;
    box-sizing: border-box;
    background: transparent;
    padding: 24px 20px;
    font-size: 16px;
    line-height: 124px;
    font-weight: 500;
    color: $white;
    font-feature-settings: normal;
    font-variant-numeric: normal;
    font-feature-settings: 'salt' on, 'ss01' on, 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on;
    border: 1.2px solid #3B414F;
    border-radius: 10px;

    @media (prefers-color-scheme: dark) {
      color: red;
    }

    &:focus {
      outline: none;
      background: transparent;
    }
  }

  textarea {
    resize: none;
    min-height: 80px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
  {
    -webkit-box-shadow: 0 0 0 30px #f5f8fa inset !important;
    -webkit-text-fill-color: #272e33;
  }

  input[type=email] {
    text-transform: lowercase;
  }

  &.input--icon-left {
    i {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);

      &:before {
        font-size: 24px;
      }
    }

    input {
      padding: 12px 12px 12px 44px;
      height: 40px;

    }
  }

  &--v2 {
    input {
      height: 40px;
      padding: 12px 12px 12px 44px;
      background: $white;
      border-color: $white;
      color: $shuttle-gray;

      &::placeholder {
        color: $shuttle-gray;
      }
    }
  }
  &--v3 {
    input {
      background: $shade-dark;

    }
  }
}