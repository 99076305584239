@font-face {
  font-family: 'icomoon';
  src:  url('../../../../public/fonts/icomoon.eot?');
  src:  url('../../../../public/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
  url('../../../../public/fonts/icomoon.ttf?') format('truetype'),
  url('../../../../public/fonts/icomoon.woff?') format('woff'),
  url('../../../../public/fonts/icomoon.svg?#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    display: block;
  }
}


.icon-right-arrow-icon:before {
  content: "\e923";
  color: #fff;
}
.icon-papel-logo:before {
  content: "\e924";
  color: #fff;
}
.icon-close:before {
  content: "\e922";
  color: #fff;
}
.icon-arrow-right:before {
  content: "\e921";
}
.icon-mobile-menu:before {
  content: "\e920";
  color: #fff;
}
.icon-search:before {
  content: "\e91f";
  color: #616675;
}
.icon-linkedin:before {
  content: "\e900";
  color: #fff;
}
.icon-m:before {
  content: "\e901";
  color: #fff;
}
.icon-twitter:before {
  content: "\e902";
  color: #fff;
}
.icon-instagram:before {
  content: "\e903";
  color: #fff;
}
.icon-add:before {
  content: "\e904";
}
.icon-heart:before {
  content: "\e905";
}
.icon-play-circle:before {
  content: "\e906";
}
.icon-smartphone:before {
  content: "\e907";
}
.icon-earth:before {
  content: "\e908";
}
.icon-arrow-right-up-line:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90a";
}
.icon-arrow-right-line:before {
  content: "\e90b";
}
.icon-electric:before {
  content: "\e90c";
}
.icon-water:before {
  content: "\e90d";
}
.icon-fire:before {
  content: "\e90e";
}
.icon-monitor:before {
  content: "\e90f";
}
.icon-joystick:before {
  content: "\e910";
}
.icon-hand-heart:before {
  content: "\e911";
}
.icon-gift:before {
  content: "\e912";
}
.icon-security:before {
  content: "\e913";
}
.icon-google-fill:before {
  content: "\e914";
}
.icon-apple-fill:before {
  content: "\e915";
}
.icon-papel:before {
  content: "\e916";
}
.icon-cloud:before {
  content: "\e917";
}
.icon-shopping-bag-line:before {
  content: "\e918";
}
.icon-restaurant-fill:before {
  content: "\e919";
}
.icon-t-shirt-line:before {
  content: "\e91a";
}
.icon-wifi:before {
  content: "\e91b";
}
.icon-qr-code:before {
  content: "\e91c";
}
.icon-bank-card:before {
  content: "\e91d";
}
.icon-check:before {
  content: "\e91e";
}