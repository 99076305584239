.section-business {
  $section-business: &;
  position: relative;
  padding-top: 160px;
  background: radial-gradient(184.72% 73.41% at 50% 62.36%, #ffffff 0.01%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(127.92% 121.25% at 50% 123.75%, #ffffff 0.01%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(
      94.36% 101.63% at 100% 0%,
      rgba(180, 232, 255, 0.3) 14.06%,
      rgba(172, 230, 255, 0.3) 52.3%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(103.16% 103.16% at 4.11% -3.16%, rgba(203, 186, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(101.6% 109.19% at 31.22% 14.98%, rgba(255, 255, 255, 0) 0%, #ffffff 100%), #0c0d10;

  .container {
    > .ant-row {
      //margin-bottom: 200px;
    }
  }

  &__text {
    align-items: center;

    h2 {
      @include phone {
        margin-bottom: 20px;
      }
    }
  }

  &__animation {
    &__item {
      cursor: pointer;

      h3 {
        opacity: 0.2;
        font-weight: 500;
        color: $shade-dark;
        font-size: 56px;
        line-height: 67px;
        font-feature-settings: "ss03" on, "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on, "cv06" on, "cv07" on,
          "cv09" on, "cv10" on, "cv11" on, "ss01" on;

        + p {
          color: $shade-dark;
          margin-top: 20px;

          + .button--link {
            margin-top: 16px;
          }
        }
      }

      p,
      button {
        display: none;
        color: $shade-dark;

        span,
        [class^="icon-"] {
          color: $shade-dark;
        }
      }

      &.active {
        h3,
        p,
        button {
          opacity: 1;
        }

        p {
          display: block;
        }

        button {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      + #{ $section-business }__animation__item {
        margin-top: 120px;

        @include phone {
          display: none;
        }
      }
    }

    .image {
      z-index: 3;

      @include phone {
        order: 5;
      }
    }

    .papel-pos-card {
      width: 508px;

      img {
        max-width: 100%;
        min-width: 100%;
      }
    }

    .pos {
      margin-top: -75px;
      position: absolute;
      left: -140px;
      top: 285px;
      width: 100%;
      z-index: -1;

      img {
        max-width: 100%;
        min-width: 100%;
      }
    }

    .pos-card-color {
      position: absolute;
      z-index: -2;
      bottom: -18px;
      right: 80px;
      width: 382px;

      img {
        max-width: 100%;
        min-width: 100%;
      }
    }

    .teams {
      &-1 {
        width: 614px;

        img {
          max-width: 100%;
          min-width: 100%;
        }
      }

      &-2 {
        width: 611px;
        margin-top: -60px;

        img {
          max-width: 100%;
          min-width: 100%;
        }
      }

      &-3 {
        width: 614px;
        margin-top: -60px;

        img {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }

    .customer {
      &-1,
      &-2,
      &-3,
      &-4 {
        position: absolute;

        img {
          max-width: 100%;
          min-width: 100%;
        }
      }

      &-1 {
        width: 565px;
        bottom: 0;
        left: -75px;
      }

      &-2 {
        width: 168px;
        right: 150px;
        top: 100px;
      }

      &-3 {
        width: 396px;
        right: 0;
        bottom: 100px;
      }

      &-4 {
        width: 337px;
        top: 150px;
        left: -55px;
      }
    }

    .border-effect {
      position: relative;

      @include phone {
        order: 2;
        margin-top: 56px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -99px;
        width: 1px;
        height: 100%;
        background: $white;
        opacity: 0.4;

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          left: -59px;
        }

        @include phone {
          display: none;
        }
      }

      &__line {
        position: absolute;
        top: 75%;
        left: -100px;
        width: 3px;
        height: 210px;
        background: #ffffff;
      }
    }
  }

  .ant-row + .ant-row {
    /*@media screen and (min-width: 1248.5px) {
      margin-top: 200px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      margin-top: 120px;
    }

    @include phone {
      margin-top: 60px;
    }*/
  }
  .accessory-dashboard-div{
    perspective: 370px;

    figure {
      transform-style: preserve-3d;
      transform-origin: center bottom;
    }
  }
  // .accessory-dashboard {
  //   perspective: 370px;
  //   margin-bottom: -160px;

  //   img {
  //     transform-origin: bottom;
  //     transform-style: preserve-3d;
  //   }
  // }

  .accessory-dashboard,
  .accessory-card {
    img {
      width: 100%;
    }
  }

  .accessory-1 {
    position: absolute;
    @include flex-start-start;
    top: 150px;
    left: -140px;
    z-index: 3;

    .price-box {
      & + .price-box {
        @media screen and (min-width: 1248.5px) {
          margin-left: 25px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          margin-left: 12px;
        }

        @include phone {
          margin-left: 14px;
        }
      }
    }
  }

  .product {
    &--payment {
      //height: 560px;
      @include tablet {
        max-height: 302px;
      }
      @include phone {
        max-height: 440px;
      }
      figure {
        position: absolute;
        bottom: 32px;
        left: 0;
        @include tablet {
          img {
            width: 454px;
          }
        }
        @include phone {
          left: -50px;
          img {
            width: 690px;
          }
        }
      }
    }
    &--desktop {
      //height: 560px;
      @include tablet {
        max-height: 302px;
      }
      @include phone {
        max-height: 440px;
      }
      figure {
        position: absolute;
        bottom: -100px;
        right: 0;
        img {
          width: 532px;
        }
        @include tablet {
          bottom: -50px;
          img {
            width: 259px;
          }
        }
        @include phone {
          bottom: -50px;
          img {
            width: 268px;
          }
        }
      }
    }
    &--terminal {
      //height: 560px;
      @include tablet {
        max-height: 302px;
      }
      @include phone {
        max-height: 440px;
      }
      figure {
        position: absolute;
        bottom: 0;
        right: 0;
        @include tablet {
          img {
            width: 489px;
          }
        }
      }
    }
  }
}
