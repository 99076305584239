.form {
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) {
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(0deg, #3B414F, #51545E);
    border-image-slice: 1;
    background-image: linear-gradient(180deg, #000, #000), linear-gradient(0deg, #3B414F, #51545E) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-radius: 32px;
  }

  /*@media screen and (min-width: 1248.5px) {
    max-width: 612px;
    height: 765px;
  }*/

  @media screen and (min-width: 768px)  {
    max-width: 100%;
    height: 700px;
  }

  @include phone {
    padding: 10px 0;
    background: $black;
  }

  > div {
    @media screen and (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 134px);
    }

  }

  &__title {
    @media screen and (min-width: 768px) {
      margin-bottom: 22px;
      color: $white;
    }

    @include phone {
      display: none;
    }
  }

  &__action {
    @media screen and (min-width: 768px) {
      @include flex-space-between;
    }

    @include phone {
      display: block;
    }

    .button {
      min-width: auto;

      @include phone {
        width: 100%;
        margin-top: 30px;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 14px;

    &-label {
      padding: 0;
      margin-bottom: 4px;

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'ss01' on, 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on;
        color: $white;

        &:before {
          display: none !important;
        }
      }
    }
  }

  .ant-row {
    margin-bottom: 0;
  }
}