.icon-circle {
  @include flex-center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 24px;
  color: $black;

  &--big {
    width: 100px;
    height: 100px;
    font-size: 60px;
  }

  &--green {
    @extend .icon-circle;
    background: $green;
  }
  &--pink {
    @extend .icon-circle;
    background: $pink;
  }
  &--aqua {
    @extend .icon-circle;
    background: $aqua;
  }
  &--yellow {
    @extend .icon-circle;
    background: $yellow;
  }
}