@import "src/assets/styles/Settings/mixins";
@import "src/assets/styles/Settings/colors";
@import "src/assets/styles/Settings/screens";

.section-personal {
  position: relative;
  overflow-x: hidden;

  @media screen and (min-width: 1248.5px) {
    padding: 156px 0 700px;
  }

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    padding: 106px 0 700px;
  }

  @include phone {
    padding: 60px 0 314px;
  }

  .container {
    > div {
      max-width: 544px;

      .text--h2 {
        margin-bottom: 34px;

        @include phone {
          font-size: 40px;
          line-height: 48px;
          text-align: center;
          margin-bottom: 232px;
        }
      }

      .button {
        margin-bottom: 56px;
      }

      .price-box-wrapper {
        @include flex-center-start;

        .price-box {
          + .price-box {
            @media screen and (min-width: 1248.5px) {
              margin-left: 24px;
            }

            @media screen and (max-width: 1248px) {
              margin-left: 12px;
            }
          }
        }
      }
    }

    > figure {
      position: absolute;
      z-index: -1;

      @media screen and (min-width: 1248.5px) {
        top: -120px;
        right: 0;
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        top: 180px;
        right: 0;
        height: 821px;
      }

      @include phone {
        top: 180px;
        right: 0;
        height: 540px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.section-daily-transaction {
  @include flex-center;
  position: relative;
  background: linear-gradient(109.31deg, #ff9e98 7.33%, rgba(255, 207, 204, 0) 75.05%),
    linear-gradient(359.9deg, #caf6d9 -7.61%, rgba(202, 246, 217, 0) 111.71%),
    linear-gradient(354.35deg, #ad00ff -12.34%, rgba(220, 211, 248, 0) 100.15%), #c172e6;

  @media screen and (min-width: 1248.5px) {
    height: 900px;
  }

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    height: 532px;
  }

  @include phone {
    height: 490px;
  }

  .text-daily-transaction {
          overflow-x: hidden;
               width: 100%;
    span {
      @include flex-center-start;
      color: rgba(30, 33, 41, 1);
      white-space: nowrap;


  
      figure {
        margin: 0 24px;
      }
    }
  }

  > figure {
    position: absolute;
    @media screen and (min-width: 1248.5px) {
      top: -244px;
      right: 310px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      top: -234px;
      right: 91px;
    }

    @include phone {
      top: -158px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      @media screen and (min-width: 1248.5px) {
      }

      @media screen and (min-width: 768px) and (max-width: 1248px) {
        height: 460px;
      }

      @include phone {
        height: 400px;
      }
    }
  }
}

.section-business {
  overflow-x: hidden;
  .ant-col-md-offset-2 {
    margin-left: 4.33%;
  }
  $section-business: &;
  &.dark {
    background: $black;

    .text--p {
      color: $white;
    }

    #{ $section-business }__animation {
      &__item {
        @include phone {
          text-align: center;
        }

        + .section-business__animation__item {
          margin-top: 170px;

          @include phone {
            display: none;
          }
        }

        h3 {
          color: $white;

          + p {
            color: $white;
          }
        }

        .button--link--dark {
          span,
          i {
            color: $white;
          }
        }
      }

      figure {
        img {
          display: block;

          @media screen and (min-width: 1248.5px) {
            margin: 0 auto;
          }

          @media screen and (min-width: 768px) and (max-width: 1248px) {
            
            margin: 0 0 0 auto;
          }

          @include phone {
            margin: 0 auto;
            width: 100%;
          }
        }
      }
    }

    #{ $section-business }__for-you {
      .text--h1 {
        text-align: center;
        @include tablet {
          font-size: 64px;
          line-height: 77px;
        }
        @include phone {
          font-size: 40px;
          line-height: 48px;
        }
      }

      @include phone {
        display: none;
      }
    }
  }
}

.section-money {
  background: $shade-light;
  @media screen and (min-width: 1248.5px) {
    padding: 170px 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1248px) {
    padding: 92px 0;
  }

  @include phone {
    padding: 80px 0;
  }

  .text {
    &--h3 {
      @include desktop {
        max-width: 648px;
        margin-bottom: 52px;
      }

      @include tablet {
        font-size: 28px ;
        line-height: 34px;
        margin-bottom: 32px;
      }

      @include phone {
        margin-bottom: 10px;
        font-size: 40px;
        line-height: 48px;
      }
    }
    &--h4 {
      @include desktop {
        margin-bottom: 160px;
      }

      @include tablet {
        margin-bottom: 68px;
        font-size: 16px;
        line-height: 24px;
      }
      @include phone {
        margin-bottom: 82px;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .product-box {
    @include flex-start-between-column;

    .text {
      &--h3 {
        @include tablet {
          font-size: 28px;
          line-height: 34px;
        }
        @include tablet {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }

    @media screen and (min-width: 1248.5px) {
      height: 645px;
      padding: 60px 82px;
    }

    @media screen and (min-width: 768px) and (max-width: 1248px) {
      height: 302px;
      padding: 24px;
    }

    @include phone {
      height: 440px;
      padding: 40px 34px;
      align-items: center;
      justify-content: flex-start;
    }

    &__title {
      @include phone {
        text-align: center;
      }
    }

    &--spent {
      .text {
        &--h3 {
          @include tablet {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
      figure {
        position: absolute;

        @media screen and (min-width: 1248.5px) {
          top: 126px;
          right: 146px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          top: 80px;
          right: 50px;
          width: 328px;
        }
        @include phone {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 306px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    &--money {
      background: $black;

      .text {
        &--h3 {
          @include tablet {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }

      figure {
        position: absolute;

        @media screen and (min-width: 1248.5px) {
          top: 263px;
          left: -20px;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          top: 112px;
          left: 45%;
          transform: translateX(-50%);
          width: 250px;
        }
        @include phone {
          bottom: -50px;
          left: 45%;
          transform: translateX(-50%);
          width: 334px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    &--abroad {

      .text {
        &--h3 {
          @include tablet {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
      
      figure {
        position: absolute;

        @media screen and (min-width: 1248.5px) {
          bottom: 0;
          right: 0;
        }

        @media screen and (min-width: 768px) and (max-width: 1248px) {
          bottom: 0;
          right: 0;
          width: 270px;
        }
        @include phone {
          bottom: -120px;
          right: 0;
          width: 400px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.send-receive {
  position: relative;
  margin-left: 50px;

  @include tablet {
    margin-left: 0;
  }
  @include phone {
    margin-left: 0;
  }

  &-avatar {
    position: absolute;
    right: -200px;
    top: -18px;
    @include tablet {
      right: 0;
      top: -78px;
    }
    @include phone {
      right: 0;
      top: -78px;
    }
  }

  &-arrow {
    position: absolute;
    top: 265px;
    right: -25px;
    width: 68px;

    @include tablet {
      transform: rotate(90deg);
      top: 290px;
      right: 45px;
    }
    @include phone {
      transform: rotate(90deg);
      top: 290px;
      right: 45px;
    }

    img {
      min-width: 100%;
      max-width: 100%;
    }
  }

  &-iban {
    position: absolute;
    top: 310px;
    right: -180px;
    width: 356px;

    @include tablet {
      top: 340px;
      right: 20px;
    }
    @include phone {
      top: 340px;
      right: 20px;
    }

    img {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.request-money {
  position: relative;
  margin-left: 50px;

  &-content {
    position: absolute;
    width: 418px;
    top: 30%;
    transform: none;
    right: -30px;

    img {
      width: 100%;
    }
  }
}
