.button {
  @include inline-flex-center;
  min-width: 160px;
  height: 48px;
  padding: 16px 24px;
  box-sizing: border-box;
  cursor: pointer;
  background: $shade-dark;
  border: 1.2px solid $shade-light;
  border-radius: 10px;

  @include phone {
    padding: 12px;
  }

  &.button--center {
    @include flex-center;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.14;
    color: $white;
  }

  &:hover {
    background: $shade-dark;
    border-color: $shade-light;

    span {
      color: $white;
    }
  }

  &--gradient-btn {
    background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%), #C172E6;
    border-radius: 10px;
    border: none;

      span {
        color: $black;

      }
      i:before {
        color: $black;
      }
      &:hover{
        background: linear-gradient(109.31deg, #FF9E98 7.33%, rgba(255, 207, 204, 0) 75.05%), linear-gradient(294.4deg, #AD00FF 4.41%, rgba(220, 211, 248, 0) 47.56%), linear-gradient(322.2deg, #CAF6D9 32.72%, rgba(202, 246, 217, 0) 85.12%), #C172E6;
        span {
          color: $black;
  
        }
      }
  }
  &--wht {
    background: $white;
    border-radius: 10px;
    border-color: none;

      span {
        color: $black;

      }
      i:before {
        color: $black;
      }
      &:hover{
        background: $white ;
        span {
          color: $black;
  
        }
      }
  }

  &--gradient {
    position: relative;
    border: none;
    background: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../../public/images/gradient-bg.png");
      background-size: cover;
      z-index: 0;
      border-radius: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #000;
      z-index: 1;
      border-radius: 11px;
    }

    > * {
      z-index: 2;
    }

  }

  [class^="icon-"], [class*=" icon-"] {
    color: $white;

    &:before {
      font-size: 24px;
    }
  }

  &--big {
    min-width: 200px;
    height: 56px;

    button {
      span {
        font-size: 16px;
      }
    }
  }

  &--small {
    min-width: 80px;
    height: 40px;

    button {
      span {
        font-size: 12px;
      }
    }
  }

  &--left-icon {
    padding: 12px 24px 12px 16px;
    > span {
      margin-left: 12px;
    }
  }

  &--right-icon {
    padding: 12px 24px 12px 16px;

    > span {
      margin-right: 12px;
    }
  }

  &--only-icon {
    @include inline-flex-center;
    background: none;
    border: none;
    min-width: 24px;
    height: auto;
    cursor: pointer;
    padding: 4px;

    i {
      display: block;
    }

    &:hover {
      background: none;
      border: none;
    }
  }

  &--both-icon {
    @include flex-space-between;
    min-width: 182px;
  }

  &--link {
    background: none;
    border: none;
    padding: 0;

    span {
      font-size: 16px;
      line-height: 24px;
      color: $white;
    }

    [class^="icon-"], [class*=" icon-"] {
      color: $white;
    }

    &:hover {
      background: none;
      border: none;
    }

    &--dark {
      @extend .button--link;

      span, [class^="icon-"], [class*=" icon-"] {
        color: $shade-dark;
      }

      &:hover {
        background: transparent;
        border-color: transparent;

        span {
          color: $shade-dark;
        }
      }
    }
    &--dark-bordered {
      @extend .button--link;

      span, [class^="icon-"], [class*=" icon-"] {
        color: $shade-dark;
      }

      &:hover {
        background: transparent;
        border-color: $shade-dark;

        span {
          color: $shade-dark;
        }
      }
    }
  }

  &--dark-bordered {
    background: transparent;
    border-color: $shade-dark;
    span, [class^="icon-"], [class*=" icon-"] {
      color: $shade-dark;
    }

    &:hover {
      background: transparent;
      border-color: $shade-dark;

      span {
        color: $shade-dark;
      }
    }
  }

  &--primary {
    background: transparent;
    border-color: $white;

    span {
      color: $white;
    }

    &:hover {
      background: transparent;
      border-color: $white-03;
    }

    &--03 {
      @extend .button--primary;
      border-color: $white-03;

      &:hover {
        background: transparent;
        border-color: $white-03;
      }

      &.active {
        background: $shade-dark;
        border-color: $black-03;

        &:hover {
          background: $shade-dark;
          border-color: $black-03;
        }
      }
    }
  }

  &--secondary {
    background: transparent;
    border-color: $black-03;

    span {
      color: $black-05;
    }

    &:hover {
      background: transparent;
      border-color: $black-03;

      span {
        color: $black-05;
      }
    }

    &.active {
      background: $shade-dark;

      span {
        color: $white;
      }

      &:hover {
        background: $shade-dark;

        span {
          color: $white;
        }
      }
    }
  }

  &--tertiary {
    background: $white;
    border-color: $white;

    span, [class^="icon-"], [class*=" icon-"] {
      color: $shade-dark;
    }

    &:hover {
      background: $white;
      border-color: $white;

      span {
        color: $shade-dark;
      }
    }
  }

  &--gradient-1 {
    position: relative;
    border: 1.2px solid transparent;
    background: $shade-dark;

    > * {
      z-index: 2;
    }
  }
}