@import '../../../assets/styles/Settings/colors.scss';
@import '../../../assets/styles/Settings/screens.scss';

.rotatey {
  position: relative;
  z-index: 2;
  transform-origin: bottom;
  transform-style: preserve-3d;

  .form {

    &__div {

      &-group {
        position: relative;
        margin-bottom: 18px;

        .error {
          position: absolute;
          bottom: -20px;
          left: 0;
        }
      }

    }

    &__btn-group {
      position: relative;
      margin-top: 20px;

      .checkbox {
        margin-bottom: 40px;
      }

      .agreement-btn {
        color: $purple-base;
        cursor: pointer;
      }

      .button {
        min-width: 100%;
      }

      .error {
        position: absolute;
        bottom: 60px;
        left: 0;
      }
    }

    label {
      color: $white;
    }

    .error {
      font-size: 12px;
      color: red;
    }

    input, textarea {
      width: 100%;
      height: 72px;
      box-sizing: border-box;
      background: transparent;
      padding: 24px 20px;
      font-size: 16px;
      font-weight: 500;
      color: #9296A5;
      font-feature-settings: normal;
      font-variant-numeric: normal;
      font-feature-settings: 'salt' on, 'ss01' on, 'ss03' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv09' on, 'cv10' on, 'cv11' on;
      border: 1.2px solid #3B414F;
      border-radius: 10px;

      &:focus {
        outline: none;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active
    {
      -webkit-text-fill-color: #9296A5;
      -webkit-background-clip: text;
    }

    input[type=email] {
      text-transform: lowercase;
    }
  }
}
