.tab {
  .ant-tabs-nav {
    margin-bottom: 80px;
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-ink-bar {
      display: none;
      background: $shade-dark;
    }

    .ant-tabs-tab {
      width: 100%;
      padding-bottom: 32px;
      

      span {
        color: $shade-dark-23;
      }

      &.ant-tabs-tab-active {
        border-color: $black;

        .ant-tabs-tab-btn, span {
          color: $shade-dark;
        }
      }

      > div {
        margin: 0 auto;
      }
    }
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
}
.ant-tabs-top {
  .ant-tabs-tab {
    border-bottom: 2px solid $black-02;
  }
}
.ant-tabs-left {
  
  .ant-tabs-tab {
    
    &-active {
      border-right: 2px solid $black-02;
    }
  }
}