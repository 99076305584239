.section-contact {
  position: relative;
  padding: 100px 0 400px;


  .text--h3 {
    + span {
      display: block;

      @media screen and (min-width: 1248.5px) {
        margin-top: 20px;
      }

      @media screen and (max-width: 1248px) {
        margin-top: 16px;
      }
    }
  }

  // .form {
  //   z-index: 2;

  //   @media screen and (min-width: 768px) and (max-width: 1248px) {
  //     margin-top: 64px;
  //   }

  //   @include phone {
  //     margin-top: 40px;
  //   }

  //   .ant-form-item {
  //     @media screen and (min-width: 768px) and (max-width: 1248px) {
  //       margin-bottom: 40px;
  //     }
  //   }

  //   .input {
  //     input {
  //       @media screen and (min-width: 768px) and (max-width: 1248px) {
  //         height: 48px;
  //         padding: 12px 16px;
  //       }
  //     }
  //   }
  // }

  &__accessory {
    position: absolute;
    bottom: 0;
    left: -150px;
    font-size: 240px;
    @include tablet {
      left: -50px;
      font-size: 150px;
    }

    span {
      width: 100%;
      display: block;
      @include phone {
        max-width: 400px;
      }
    }
  }
}
.agreement-group {
  align-items: center;
  .agreement-btn {
    color: $purple-base;
    cursor: pointer;
  }
}
