@import '../../assets/styles/Settings/screens.scss';
@import '../../assets/styles/Settings/colors.scss';

.ant-row {
    justify-content: center;
}

.section-businesspage {
    background: $shade-light;
    padding: 100px 0 450px;
    position: relative;
    @include phone {
        padding: 100px 0 280px;
    }

    .text--subtitle {
        color: $shade-mid;
    }
    h2 {
        margin-bottom: 50px;
    }
    h6 {
        margin-bottom: 50px;
    }
    .button--secondary {
        margin-left: 16px;
    }

    
}

.section-man {
    background: $black;
    padding: 350px 0 300px;
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1248px) {
        padding: 250px 0 300px;
    }

    @include phone {
        padding: 200px 0 300px;
    }

    .text {

        @include tablet {
            &--h3 {
                font-size: 28px;
                line-height: 34px;
            }
        }
        @include phone {
            &--h3 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }

    .img-row {      
        position: absolute;
        top: -450px;
        figure {
            width: 100%;
            img {
                width: 100%;
            }
        }
        @include phone {
            top: -220px;
        }
        @media screen and (min-width: 768.2px) and (max-width: 1248px) {
            top: -350px;
        }
    }

    .grid-template {
        display: grid;
        gap: 20px;
        grid-template-columns: calc(50% - 20px);
        grid-template-areas: "send-receive send-receive" "virtual payment" "paylink payment";
        @include phone {
            grid-template-columns: calc(100% - 20px);
            grid-template-areas: "send-receive" "virtual" "paylink""payment";
        }
    }
    .product--send-receive {
        height: 646px;
        grid-area: send-receive;
        @include tablet {
            height: 302px;
        }
        @include phone {
            height: 374px;
            padding: 34px 10px 0;
            .text--h3 {
                text-align: center;
                padding-top: 0;
                @include tablet {
                    font-size: 28px !important ;
                    line-height: 34px;
                }
            }
            button {
                top: 80px;
                left: 35%;

            }

        }

        figure {
            position: absolute;
            bottom: 0;
            right: 10%;

            @include phone {
                position: relative;
                display: block;
                margin: 60px auto 0;

            }
            @include tablet {
                img {
                    width: 300px;
                }
                
            }
        }
        .button--link--dark {
            position: absolute;
            bottom: 50px ;
        }
    }
    .product-box--virtual {
        grid-area: virtual;
        height: 646px;
            @include tablet {
                height: 302px;
            }
            @include phone {
                height: 374px;
                padding: 34px 10px 0;
            }
        figure {
            position: absolute;
            bottom: 0;
            left: 0;
            @include tablet {
                img {
                    min-width: 320px;
                }
            }
            @include phone {
                img {
                    min-width: 350px;
                }
            }
            
        }
        .text--h3{
            position: absolute;
            z-index: 1;
            @include phone {
                text-align: center;
                position: relative;
            }
        }


    }
    .product-box--payment {
        grid-area: payment;
        @include phone {
            height: 743px;
        }
        .text--h3 {
            @include phone {
                position: relative;
                text-align: center;
            }
        }
        figure {
            position: absolute;
            height: 1519.0186767578125px;
            width: 1109.0989990234375px;
            left: -245px;
            top: -149px;
            @include tablet {
                width: 520px;
                height: 710px;
                top: -41px;
                left: -103px;
                img {
                    width: 520px;
                }

            }
            @include phone {
                width: 826px;
                height: 979px;
                top: -148px;
                left: -205px;
                img {
                    width: 826px;
                }

            }
            
            // img {
            //     width: 100%;
            //     @include tablet {
            //         width: 518px;
            //         left: -103px;
            //     }
            //     @include phone {
            //         width: 826px;
            //     }
            // }
        }

    }
    .product-box--paylink {
        grid-area: paylink;
        height: 646px;
        padding: 34px 30px 0;
        @include tablet {
            height: 302px;
        }
        @include phone {
            height: 374px;
        }

        .text--h3 {
            @include phone {
                position: relative;
                text-align: center;
            }
        }
        figure {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 689px;
            img {
                width: 749px;
            }
            @include tablet {
                position: relative;
                margin: 0 auto;
                display: block;
                img {
                    width: 322px;
                }
            }
            @include phone {
                width: 406px;
                margin: 50px auto 0;

                
            }
        }
    }
}

.section-business-terminal {
    background: $shade-light;
    padding: 100px 0 250px;
    position: relative;
    h3 {
        margin-bottom: 50px;
    }
    .text {

        @include tablet {
            &--h3 {
                font-size: 28px;
                line-height: 34px;
            }
        }
        @include phone {
            &--h3 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }
    .product-box {
        height: 612px;

        .text-group {
            position: absolute;
            z-index: 2;
            width: 100%;
            left: 0;
            top: 0;

            @include phone {
                top: -30px;
            }


            span {
                display: block;
            }
        }
        @include tablet {
            min-width: 312px;
            height: 364px;
        }
        @include phone {
            min-width: 342px;
            height: 342px;
        }
        &--terminal-1 {
            background: $black;

            @include tablet {
                min-width: 312px;
                height: 364px;
            }
            @include phone {
                min-width: 342px;
                height: 342px;
            }

            figure {
                position: absolute;
                top: 117;
                left: -188px;
                z-index: 0;
                height: 522px;
                width: 1338px;
                left: -378px;
                top: 124px;
                border-radius: 0px;


                @include tablet {
                    top: 117px;
                    left: -188px;
                    width: 634px;
                    min-width: 634px;
                    img {
                        width: 634px;
                        min-width: 634px;
                    }
                }
                @include phone {
                height: 266px;
                width: 681.8161010742188px;
                left: -213px;
                top: 112px;
                border-radius: 0px;
                    img {

                    }
                }

            }


        }
        &--terminal-2 {
            padding-left: 0;
            padding-right: 0;
            background: $white;
            @include tablet {
                min-width: 312px;
                height: 364px;
            }
            @include phone {
                min-width: 342px;
                height: 342px;
                
            }
            figure {
                position: relative;
                display: block;
                width: 470px;
                margin: 200px auto 0 ;
                @include tablet {
                    width: 292px;
                    margin: 100px auto 0 ;
                    img {
                        width: 292px; 
                    }
                }
                @include phone {
                    margin: 100px auto 0 ;
                    width: 242px;
                }

            }
            .text-group {

                .button--dark {
                    color: $black;
                    background-color: transparent;
                    border: none;
                    span{
                        color:$black;
                    }
                    i {
                        color:$black;
                    }
                }
            }


        }
        &--terminal-3 {
            background: $white;
            figure {
                width: 284px;
                position: relative;
                display: block;
                margin: 220px auto 0 ;
                @include tablet {
                    width: 153px;
                    margin: 130px auto 0 ;
                    img {
                        width: 153px;
                    }
                }
                @include phone {
                    margin: 100px auto 0 ;
                    width: 149px;
                    img {
                        width: 149px;
                    }
                }
                
            }
            .text-group {

                .button--dark {
                    color: $black;
                    background-color: transparent;
                    border: none;
                    span{
                        color:$black;
                    }
                    i {
                        color:$black;
                    }
                }
            }

        }
        &--terminal-4 {
            background: $white;
            figure {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                img {
                    width: 100%;
                }
                @include tablet {
                    max-width: 530px;
                    img {
                        max-width: 530px;
                    }
                }
                @include phone {
                    max-width: 730px;
                    img {
                        max-width: 730px;
                    }
                }
            }
            .text-group {

                .button--dark {
                    color: $black;
                    background-color: transparent ;
                    border: none;
                    span{
                        color:$black;
                    }
                    i {
                        color:$black;
                    }
                }
            }

        }
        &--terminal-5 {
            background-color: $black;
            @include phone {
                height: 213px;
                padding: 0;
            }
            figure {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                img {
                    width: 110%;
                    @include phone {
                        width: 130%;
                    }
                }
            }
            
            .text-group {
                left: 30px;
                top: 20px;
                @include phone {
                    left: 40px;
                    top: 40px;
                }
            }
            .button--link--dark {
                color: $white;
                background: none;
                border: none;
                position: absolute;
                z-index: 3;
                bottom: 50px;
            }
        }

        &--terminal-6 {
            background: $white;
            figure {
                width: 683px;
                position: absolute;
                left: -104px;
                bottom: 0;
                margin: 220px auto 0 ;
                img {
                    width: 683px;
                }
                @include tablet {
                    width: 100%;
                    max-width: 385px;
                    left: 0;
                    img {
                        width: 100%;
                        max-width: 385px;
                    }
                }
                @include phone {
                    width: 100%;
                    max-width: 405px;
                    left: 0;
                    img {
                        width: 100%;
                        max-width: 405px;
                    }
                }
                
            }
            .text-group {

                .button--dark {
                    color: $black;
                    background-color: transparent;
                    border: none;
                    span{
                        color:$black;
                    }
                    i {
                        color:$black;
                    }
                }
            }

        }
        &--terminal-7 {
            background: $black;

            @include tablet {
                padding-left: 0;
                min-width: 312px;
                height: 364px;
            }
            @include phone {
                min-width: 342px;
                height: 342px;
            }

            figure {
                position: absolute;
                height: 594px;
width: 1408px;
left: -571px;
top: 152px;
border-radius: 0px;


                @include tablet {

                    height: 317.00091552734375px;
                    width: 683.5160522460938px;
                    left: -261px;
                    top: 76px;
                    img {
                        height: 317.00091552734375px;
                        width: 683.5160522460938px;

                    }
                }
                @include phone {
                    height: 318.376708984375px;
width: 754.6707153320312px;
left: -318.5px;
top: 127.623046875px;
border-radius: 0px;

                    img {
                        height: 318.376708984375px;
                        width: 754.6707153320312px;
                    }
                }

            }


        }
    }
}

.section-special-notifications {
    padding-top: 100px;
    position: relative;

    figure {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .text {

        @include tablet {
            &--h1 {
                font-size: 56px;
                line-height: 67px;
            }
            &--h3 {
                font-size: 40px;
                line-height: 48px;
            }
        }
        @include phone {
            &--h1 {
                font-size: 56px;
                line-height: 67px;
            }
            &--h3 {
                font-size: 40px;
                line-height: 48px;
            }
        }
    }
    .bg-grey {
        padding-top: 100px;
        background-color: $black-06;
        
    }
    .bg-white {
        padding-top: 100px;
        background-color: $white;
        
    }
    .ant-row {
        justify-content: center;
    }
}
.section-foryourteam {
    background: $shade-light;
    padding: 200px 0 0;
    position: relative;
    h2 {
        margin-bottom: 50px;
    }

    .ant-row {
        align-items: center;
    }
    .frame-column {

        figure {
            margin-bottom: 20px;
            img {
                width: 100%;
            }
        }

        &__left{
            margin-top: 100px;
        }
    }
    .text-right {
        @include desktop {
            text-align: right;
        }
        @include phone {
            margin-top: 50px;
        }

    }
    .frame-merchant {
        figure {
            perspective: 370px;
            width: 100%;
            img {
                width: 100%;
                transform-origin: bottom;
                transform-style: preserve-3d;
            }
        }
    }
}

#section-send-receive{
    .text--h3{

        margin: 200px 0;
        
        @include phone {
            margin: 50px 0;
        }
    }
}

