.section-flights {
    $section-flights: &;
    position: relative;
    overflow-x: hidden;
  
    @media screen and (min-width: 1248.5px) {
      padding: 200px 0 0;
    }
  
    @media screen and (min-width: 768px) and (max-width: 1248px) {
      padding: 128px 0 0;
    }
  
    @include phone {
      padding: 82px 0 0;
    }
  
    > .container {
      figure {
        border-radius: 24px;
        overflow: hidden;
  
        img {
          display: block;
          width: 100%;
        }
      }
  
      span {
        display: block;
      }
  
      #{ $section-flights }__head {
        @media screen and (min-width: 1248.5px) {
          margin-bottom: 282px;
        }
  
        @media screen and (min-width: 768px) and (max-width: 1248px) {
          margin-bottom: 236px;
        }
  
        @include phone {
          margin-bottom: 170px;
        }
  
        figure {
          @media screen and (min-width: 1248.5px) {
            margin-top: 106px;
          }
  
          @media screen and (min-width: 768px) and (max-width: 1248px) {
            margin-top: 64px;
          }
  
          @include phone {
            margin-top: 74px;
          }
        }
      }
  
      .ant-row {
        position: relative;
        .ant-col {
          align-self: center;
          z-index: 4;
  
          @include phone {
            text-align: center;
          }
  
          figure {
            @include phone {
              max-width: 342px;
              margin: 0 auto;
              margin-top: 72px;
            }
            img {
  
            }
          }
  
          &:first-child {
            @include phone {
              order: 2;
            }
          }
        }
  
        &.even {
          .ant-col {
            @media screen and (min-width: 768px) {
              text-align: right;
            }
  
            @include phone {
              text-align: center;
            }
          }
        }
  
        + .ant-row {
          @media screen and (min-width: 1248.5px) {
            margin-top: 360px;
          }
  
          @media screen and (min-width: 768px) and (max-width: 1248px) {
            margin-top: 426px;
          }
  
          @include phone {
            margin-top: 292px;
          }
        }
      }
  
      .accessory-1 {
        @include flex-center;
        position: absolute;
        z-index: 1;
  
        @media screen and (min-width: 1248.5px) {
          top: 800px;
          left: 72px;
        }
  
        @media screen and (min-width: 768px) and (max-width: 1248px) {
          top: 550px;
          left: 0;
        }
  
        @include phone {
          top: 450px;
          left: -100px;
        }
  
        figure {
          margin: 0 40px;
          width: 120px;
        }
      }
      .accessory-2 {
        @include flex-center;
        position: absolute;
        top: -260px;
        left: -900px;
        z-index: 1;
  
        figure {
          margin: 0 40px;
          width: 120px;
        }
      }
    }
  }