.chat-message {
  @include flex-center-start;
  $chat-message: &;

  &__avatar {
    @include flex-center-start;
    border-radius: 50%;
    filter: drop-shadow(0px 22.3837px 41.0368px rgba(0, 0, 0, 0.25));
    background: $purple;
    @media screen and (min-width: 1248.5px) {
      width: 80px;
      height: 80px;
    }

    @media screen and (max-width: 1248px) {
      width: 40px;
      height: 40px;
      margin-right: 6px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    #{ $chat-message }__item {
      @include inline-flex-center-start;
      background: #d7ccfc;
      box-shadow: 0 1.60894px 3.21788px rgba(146, 150, 165, 0.12);
      @media screen and (min-width: 1248.5px) {
        border-radius: 40.2235px;
        padding: 6px 6px; 
      }

      @media screen and (max-width: 1248px) {
        padding: 4px 8px;
        border-radius: 25px;
      }

      span {
        font-weight: 500;
        letter-spacing: 0.02em;
        font-feature-settings: "ss03" on, "cv01" on, "cv02" on, "cv03" on, "cv04" on, "cv05" on, "cv06" on, "cv07" on,
          "cv09" on, "cv10" on, "cv11" on, "ss01" on;
        color: $shade-dark;

        @media screen and (min-width: 1248.5px) {
          font-size: 12px;
          line-height: 19px;
        }

        @media screen and (max-width: 1248px) {
          font-size: 8px;
          line-height: 12px;
        }
      }

      + #{ $chat-message }__item {
        margin-top: 8px;
      }
    }
  }

  &--small {
    #{ $chat-message }__avatar {
      @media screen and (min-width: 1248.5px) {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      @media screen and (max-width: 1248px) {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &--multi {
  }

  &--recipient {
    justify-content: flex-end;

    #{ $chat-message }__item-wrapper {
      align-items: flex-end;
    }
  }

  &--yellow {
    #{ $chat-message }__item,
    #{ $chat-message }__avatar {
      background: $yellow;
    }
  }

  &--aqua {
    #{ $chat-message }__item,
    #{ $chat-message }__avatar {
      background: #93d5f9;
    }
  }

  &--green {
    #{ $chat-message }__item,
    #{ $chat-message }__avatar {
      background: #c0f1ca;
    }
  }

  &--pink {
    #{ $chat-message }__item,
    #{ $chat-message }__avatar {
      background: $pink;
    }
  }

  &--gray {
    #{ $chat-message }__item,
    #{ $chat-message }__avatar {
      background: #dbdadb;
    }
  }

  /*.chat-messages-wrapper & {
    + #{ $chat-message } {
      margin-top: 10px;
    }
  }*/
}
