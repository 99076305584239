// @import '../../assets/styles/Settings/colors.scss';
// @import '../../assets/styles/Settings/mixins.scss';
// @import '../../assets/styles/Settings/screen.scss';

.header {
  $header: &;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 12;
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0 2px 0.375rem 0 rgba(37,51,66,.09);
  transition: top .2s ease;


  @media screen and (min-width: 768px) {
    padding: 38px 0;
  }

  @include phone {
    padding: 24px 0;
  }


  ::-webkit-scrollbar {
    width: 18px;
  }

  .dropdown {
    .ant-dropdown-menu {
      background-color: $white-01;

      a {
        color: $white;
      }
    }
  }

  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /*@include desktop {
      @include flex-space-between;
    }*/

    #{ $header }__logo {
      @media screen and (min-width: 1248.5px) {
        margin-right: 116px;
      }
    }

    #{ $header }__menu {
      @media screen and (min-width: 1248.5px) {
        height: 40px;
      }
    }

    #{ $header }__right {
      @include flex-center-end;


      // @media screen and (min-width: 1248.5px) {
      //   display: none;
      // }

      button {
        span {
          line-height: 1;
        }
      }
      &__language-btn {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        color: $white-05;
        padding: 4px;
        margin-right: 24px;
        
        i {
          font-size: 28px;
          margin-right: 4px;
          margin-bottom: 2px;
        }
        &:hover {
          opacity: .8;
        }
        &--white {
          color:$black;
          &:hover {
            opacity: .6;
          }
        }

      }

      &__btn-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .button {
          + .button {
            margin-left: 20px;
          }
        }
      }


      /*#{ $header }__mobile-menu {
        @media screen and (min-width: 1248.5px) {
          display: none;
        }
        @media screen and (max-width: 1248px) {
          position: absolute;
          top: 38px;
          right: 38px;
          background: none;
          border: none;
          padding: 6px;
          margin-left: 20px;
          margin-right: -6px;
          cursor: pointer;
        }

        i {
          &:before {
            @media screen and (max-width: 1248px) {
              font-size: 24px;
            }
          }
        }
      }*/


    }
  }

  &--white {
    background: $shade-light;

    #{ $header }__menu {
      a {
        li {
          list-style: none;
          color: $shade-dark;
          @include tablet {
            color: $white;
          }
          @include phone {
            color: $white;
          }
        }
      }
    }

    #{ $header }__right {
      .select {
        &__prefix-icon {
          color: $shade-dark;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
          color: $shade-dark-07;
        }
      }

      .button--gradient {
        [class^=icon-], span {
          color: $shade-dark;
        }

        &:after {
          background: $shade-light;
        }
      }

      #{ $header }__mobile-menu {
        .icon-close {
          &:before {
            color: $white;
          }
        }

        i {
          &:before {
            color: $shade-dark;
          }
        }
      }

      &__btn-group {
        .button-hamburger {
          i {
            &:before {
              color: $black;
            }
          }
        }
      }
    }
  }

  &--passive {
    .header__right {
      /*&--btn-group {
        @media screen and (min-width: 1248.5px) {
          button + a {
            margin-left: 16px;
          }

        }
        @include tablet {
          position: absolute;
          top: 38px;
          right: 80px;
          button + a {
            margin-left: 16px;
          }

        }
        @include phone {
          display: none;
        }
      }*/

      &--icon-group {
        @media screen and (min-width: 1248.5px) {
          display: none;
        }
        @media screen and (max-width: 1248px) {
          display: none;
        }
      }

      .copyright {
        @media screen and (min-width: 1248.5px) {
          display: none;
        }
        @media screen and (max-width: 1248px) {
          display: none;
        }
      }
    }

    .header__menu {
      @media screen and (min-width: 1248.5px) {
        @include flex-center-start;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        padding: 0;

        .search-bar {
          display: none;
        }
      }

      @media screen and (max-width: 1248px) {
        display: none;
      }

      a {
        text-decoration: none;

        li {
          list-style: none;
          font-size: 18px;

        }

        i {
          display: none;
        }

        & + a {
          margin-left: 34px;

        }
      }
    }
  }

  &--active {

    @include tablet {
      padding: 0;
      background-color: $black;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      overflow-y: scroll;
      z-index: 10;
      padding: {
        top: 0;
        bottom: 40px;
      }
    }
    @include phone {
      padding: 0;
      background-color: $black;
      min-height: 100vh;
      height: 100%;
      position: fixed;
      z-index: 10;
      padding: {
        top: 0;
        bottom: 40px;
      }
    }

    .container {
      position: relative;
      flex-direction: column;

      #{ $header }__logo {
        margin: 0 auto 0 0;
        @media screen and (min-width: 768.5px) {
          padding: 38px 0;
        }

        @include phone {
          padding: 24px 0;
        }
      }

      #{ $header }__right {
        flex-direction: column;
        width: 100%;

        .button--gradient-btn {
          width: 100%;
        }

        &__language-btn {
         
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white-05;
          padding: 4px;
          i {
            font-size: 27px;
            margin-right: 4px;
          }
          &:hover {
            opacity: .6;
          }


        }

        &__btn-group {
          padding: 20px 0;
          width: 100%;

          button {
            
            /*&:first-child {
              @include tablet {
                margin-bottom: 32px;
              }
              @include phone {
                margin-bottom: 12px;
              }
            }*/
          }

          .button-hamburger {
            position: absolute;
            @media screen and (min-width: 768px) and (max-width: 1248px) {
              top: 42px;
              right: 60px;
            }

            @include phone {
              top: 28px;
              right: 24px;
            }
          }

          @media screen and (min-width: 1248.5px) {
            display: none;

          }
        }

        &--icon-group {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          a {
            border: 1px solid #1E2129;
            border-radius: 50%;
            padding: 15px;
            margin: 5px;
            font-size: 20px;

          }

          @media screen and (min-width: 1248.5px) {
            display: none;

          }
        }

        .copyright {
          margin-top: 16px;
          color: $shuttle-gray;
          @include phone {

            font-size: 12px;
            line-height: 16px;
          }

          @media screen and (min-width: 1248.5px) {
            display: none;

          }
        }
      }

      #{ $header }__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-top: 1px solid #1E2129;
        width: 100%;
        background-color: $black;
        list-style: none;
        z-index: 1;

        @media screen and (min-width: 1248.5px) {
          display: none;

        }

        @media screen and (max-width: 1248px) {
          // @include flex-center-start;
          padding: 0;
        }

        .search-bar {
          width: 100%;
          border-bottom: 1px solid #1E2129;

          @include tablet {
            padding: 30px 0;

            input {
              height: 54px;
            }
          }
          @include phone {
            padding: 20px 0;

            input {
              height: 44px;
            }
          }

        }

        a {
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid #1E2129;

          @include tablet {
            padding: 24px 0;
          }

          @include phone {
            padding: 12px 0;
          }

          i {
            color: $white;

            @include tablet {
              font-size: 30px;
            }
            @include phone {
              font-size: 20px;
            }
          }

          li {

            @include tablet {
              font-size: 32px;
              font-weight: 400;
              line-height: 38px;
              color: $white;
            }
            @include phone {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: $white;
            }

          }

          & + a {
            // margin-left: 34px;

          }
        }
      }
    }

    &.header--white {
      .header__right__btn-group {
        .button-hamburger {
          i {
            &:before {
              color: $white;
            }
          }
        }
      }
    }
  }
}