.person {
  $person: &;
  max-width: 296px;

  &__image {
    display: block;
    width: 100%;
    height: 340px;
    border: 1px solid $shade-dark;
    border-radius: 8px;
    margin-bottom: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    display: block;
    color: $white;
    margin-bottom: 12px;
  }

  &__title {
    display: block;
    color: $white-04;
  }

  &--small {
    @include flex-center-start;
    max-width: 100%;

    #{ $person }__image {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      margin: {
        right: 32px;
        bottom: 0;
      }
    }

    #{ $person }__name {
      margin: {
        right: 8px;
        bottom: 0;
      }
    }

  }
}