.triangle {
  $triangle: &;
  position: relative;
  width: 30px;
  z-index: 14;
  overflow: hidden;

  &__inner {
    transform: rotate(-45deg);
    background-color: silver;
    width: 30px;
    height: 30px;
    top: -6px;
    left: 0;
    position: relative;
    border-radius: 5px;
  }

  &__outer {


    #{ $triangle }__inner {

    }
  }
}